import { emptySplitApi as api } from './emptyApi';

export const addTagTypes = ['getGiftcardBalance', 'getGiftcardValidation', 'getPromotionCodeValidation', 'getProfileFromNz'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getGiftcardBalance: build.query<GetGiftcardBalanceApiResponse, GetGiftcardBalanceApiArg>({
        query: queryArg => ({
          url: `/pointOfSale/giftcard/balance/${queryArg.giftcardNumber}`,
        }),
        providesTags: ['getGiftcardBalance'],
      }),
      getGiftcardValidation: build.query<GetGiftcardValidationApiResponse, GetGiftcardValidationApiArg>({
        query: queryArg => ({
          url: `/pointOfSale/giftcard/validation/${queryArg.giftcardNumber}`,
        }),
        providesTags: ['getGiftcardValidation'],
      }),
      getPromotionCodeValidation: build.query<GetPromotionCodeValidationApiResponse, GetPromotionCodeValidationApiArg>({
        query: queryArg => ({
          url: `/pointOfSale/giftcard/promotion/${queryArg.promotionCode}`,
        }),
        providesTags: ['getPromotionCodeValidation'],
      }),
      getProfileFromNz: build.query<GetProfileFromNzApiResponse, GetProfileFromNzApiArg>({
        query: queryArg => ({
          url: `/pointOfSale/profile/${queryArg.lockerNumber}`,
        }),
        providesTags: ['getProfileFromNz'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgePointOfSaleApi };
export type GetGiftcardBalanceApiResponse = /** status 200  */ GiftcardBalanceInquiry;
export type GetGiftcardBalanceApiArg = {
  giftcardNumber: string;
};
export type GetGiftcardValidationApiResponse = /** status 200  */ boolean;
export type GetGiftcardValidationApiArg = {
  giftcardNumber: string;
};
export type GetPromotionCodeValidationApiResponse = /** status 200  */ boolean;
export type GetPromotionCodeValidationApiArg = {
  promotionCode: string;
};
export type GetProfileFromNzApiResponse = /** status 200  */ ProfileInquiryResponse;
export type GetProfileFromNzApiArg = {
  lockerNumber: string;
};
export type GiftcardBalanceInquiry = {
  balance?: number;
  statusName?: string;
  remark?: string;
};
export type ProfileInquiry = {
  lockerNumber?: string;
  customerId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  phoneNumber?: string;
  admissionName?: string;
  checkInAt?: string;
  orderFromType?: number;
  isAnnounced?: number;
};
export type ProfileInquiryResponse = {
  code?: number;
  messages?: string[];
  result?: ProfileInquiry;
};
export const { useGetGiftcardBalanceQuery, useGetGiftcardValidationQuery, useGetPromotionCodeValidationQuery, useGetProfileFromNzQuery } = injectedRtkApi;
