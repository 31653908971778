import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from 'components/typography';
import Button from 'views/book/treatments/Button';

type Variant = 'warning' | 'success' | 'info' | 'error' | 'confirm' | 'warning_with_reverse_order_button' | undefined;
export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant?: Variant;
  title?: string | undefined;
  description?: string | string[] | undefined;
  hasIcon?: boolean;
  closeButtonText?: string | undefined;
  submitButtonText?: string | undefined;
}

export interface ConfirmationDialogProps extends ConfirmationOptions {
  open?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, variant, description, hasIcon, closeButtonText, submitButtonText, onSubmit, onClose }) => {
  if (!open) return <></>;

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title2" PaperProps={{ style: { display: 'flex', gap: '0px', width: '560px', padding: '15px' } }}>
      {(hasIcon || title) && (
        <DialogTitle id="alert-dialog-title2" component={'div'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {title && <h2 style={{ alignSelf: 'stretch', textAlign: 'center', fontSize: '24px' }}>{title}</h2>}
        </DialogTitle>
      )}
      <DialogContent sx={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
        {Array.isArray(description) && (
          <ul>
            {description.map((desc, index) => (
              <li key={`alert-dialog-${index}`}>
                <Typography tag="bodyBase">{desc}</Typography>
              </li>
            ))}
          </ul>
        )}
        {!Array.isArray(description) && <Typography tag="bodyBase">{description}</Typography>}
      </DialogContent>
      <DialogActions className="dialog-actions-dense" style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
        {!variant && closeButtonText && submitButtonText && (
          <>
            <Button hasBorder onClick={onClose} buttonText={closeButtonText} />
            <Button onClick={onSubmit} buttonText={submitButtonText} />
          </>
        )}
        {variant === 'warning' && (
          <>
            <Button onClick={onSubmit} buttonText="YES" />
            <Button hasBorder onClick={onClose} buttonText="NO" />
          </>
        )}
        {variant === 'warning_with_reverse_order_button' && (
          <>
            <Button hasBorder buttonText={'NO'} onClick={onClose} />
            <Button buttonText={'YES'} onClick={onSubmit} />
          </>
        )}
        {variant === 'success' && <Button buttonText={'OK'} onClick={onSubmit} />}
        {variant === 'info' && <Button buttonText={'OK'} onClick={onSubmit} />}

        {variant === 'error' && <Button buttonText={'OK'} onClick={onClose} />}
        {variant === 'confirm' && (
          <>
            <Button buttonText={'CONFIRM'} onClick={onSubmit} />
            <Button buttonText={'CANCEL'} hasBorder onClick={onClose} />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
