/** @format */

import { BaseQueryApi, BaseQueryFn, RootState } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

const axiosRequest = async (baseUrl: string, { url, method = 'GET', data, params, headers }: AxiosRequestConfig) => {
  try {
    if (typeof window === 'undefined') {
      baseUrl = process.env.API_ENDPOINT;
      url = baseUrl + url;
      headers = {
        ...headers,
        apiKey: `${process.env.API_KEY}`,
      };
    } else {
      url = baseUrl + '/api' + url;
      if (window.localStorage.getItem('accessToken')) {
        headers = headers ?? {};
        headers['Authorization'] = `Bearer ${window.localStorage.getItem('accessToken')}`;
      }
    }

    const request = {
      url: url,
      method,
      data: Array.isArray(data) ? data : { ...data },
      params,
      headers,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    };

    const { data: response } = await axios(request);

    return { data: response };
  } catch (err: any) {
    // if (err.response?.status === 500 && process.env.NODE_ENV === 'production') {
    //   window.location.href = '/500';
    //   return;
    // }
    throw {
      error: {
        status: err.response?.status,
        message: err.response?.data?.description || err.response?.data?.message || err.response?.data,
      },
    };
  }
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async (args, api, _extraOptions) => {
    const clientIp = (api?.getState() as { clientIp: { ip: string } })?.clientIp?.ip;
    let headers = args.headers;
    if (clientIp) headers = { ...headers, 'x-real-ip': clientIp, 'x-forwarded-for': clientIp };
    return axiosRequest(baseUrl, {
      ...args,
      headers,
    }).catch(error => {
      return error;
    });
  };
