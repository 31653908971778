import { ReactNode, forwardRef } from 'react';
import { FormInputProps, IDisplayEmpty, IHasChildren, InnerFormInput } from './interfaces';
import { InputLabel, Select, SelectChangeEvent, FormControl as MUIFormControl, SelectProps } from '@mui/material';
import FormControl from './formControl';

interface ISelectInputProps {
  multiple?: boolean;
  extraSelectProps?: SelectProps;
}
interface IMultipleSelectInputProps<TMenuItem extends { id: string; name: string }> {
  menuItems: TMenuItem[];
}

type InnerSelectInputProps = InnerFormInput & IHasChildren & IDisplayEmpty & ISelectInputProps;
export type SelectInputProps = FormInputProps & IHasChildren & ISelectInputProps;
type MultipleSelectInputProps<T extends { id: string; name: string }> = FormInputProps & IHasChildren & ISelectInputProps & IMultipleSelectInputProps<T>;
export const InnerSelectInput = forwardRef<React.MutableRefObject<HTMLInputElement>, InnerSelectInputProps>((props, ref) => {
  const { value, label, onChange, error, isSubmitting, multiple, extraSelectProps } = props;
  return (
    <MUIFormControl>
      <InputLabel error={Boolean(error)} id={`controlled-select-${label}`} sx={{ zIndex: 0, fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px', letterSpacing: '0.15px', top: '-7px' }}>
        {label}
      </InputLabel>
      <Select
        id={`controlled-select-${label}-id`}
        // labelId={`controlled-select-${label}`}
        error={Boolean(error)}
        label={label}
        multiple={multiple ?? false}
        renderValue={multiple ? (selected: { id: string; name: string }[]) => selected.map(s => s.name).join(', ') : null}
        value={value || getDefaultValueByType(value)}
        onChange={onChange}
        disabled={isSubmitting}
        inputRef={ref}
        sx={{ height: '40px' }}
        MenuProps={{ disableScrollLock: true }}
        {...extraSelectProps}
      >
        {props.children}
      </Select>
    </MUIFormControl>
  );
});

export const SelectInput = (props: SelectInputProps) => {
  return (
    <FormControl renderedComponent={<InnerSelectInput multiple={props.multiple} extraSelectProps={props.extraSelectProps}></InnerSelectInput>} name={props.name} label={props.label} rules={props.rules}>
      {props.children}
    </FormControl>
  );
};

export default SelectInput;

const MultipleSelectInput = <T extends { id: string; name: string }>(props: MultipleSelectInputProps<T>) => {
  return <FormControl renderedComponent={<InnerSelectInput multiple={true} menuItems={props.menuItems}></InnerSelectInput>} name={props.name} label={props.label} rules={props.rules}></FormControl>;
};

const getDefaultValueByType = (value: any) => {
  switch (typeof value) {
    case 'number':
      return 0;
    default:
      return '';
  }
};
