import { useState } from 'react';
import CleaveTextFieldInput from './cleaveTextFieldInput';
export const CreditCardInput = (props: { creditCardTypePosition?: { left: string; top: string }; hasLabel: boolean }) => {
  const [creditCardType, setCreditCardType] = useState<string | null>(null);
  return (
    <>
      <CleaveTextFieldInput
        label={props.hasLabel ? 'CreditCard' : ''}
        name="creditCard"
        autoComplete="cc-number"
        cleaveOptions={{
          creditCard: true,
          onCreditCardTypeChanged: type => setCreditCardType(type),
        }}
        placeholder="&#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;"
      />
      {creditCardType?.toLowerCase() !== 'unknown' && (
        <p
          style={{
            margin: 0,
            position: 'relative',
            textAlign: 'end',
            top: props.creditCardTypePosition?.top ?? '-39px',
            left: props.creditCardTypePosition?.left ?? '81%',
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
        >
          {creditCardType}
        </p>
      )}
    </>
  );
};
