import { forwardRef } from 'react';
import React from 'react';
import { TextField } from './textFieldInput';
import FormControl from './formControl';
import { FormInputProps, InnerFormInput } from './interfaces';
import { CleaveInput } from './lib';

type InnerPhoneInputProps = InnerFormInput;
export type PhoneInputProps = FormInputProps;
const InnerPhoneInput = forwardRef<React.MutableRefObject<HTMLInputElement>, InnerPhoneInputProps>(({ value, label, onChange, error, isSubmitting }, ref) => {
  return (
    <TextField
      label={label}
      inputRef={ref}
      disabled={isSubmitting}
      autoComplete="tel-national"
      inputMode="numeric"
      error={Boolean(error)}
      helperText={error?.message}
      type="tel"
      InputProps={{ inputComponent: CleaveInput }}
      inputProps={{
        value,
        onChange,
        ref,
        options: {
          numericOnly: true,
          blocks: [3, 3, 4],
        },
      }}
    />
  );
});
export const PhoneInput = (props: PhoneInputProps) => {
  return <FormControl renderedComponent={<InnerPhoneInput />} name={props.name} label={props.label} rules={props.rules} />;
};
export default PhoneInput;
