import { forwardRef } from 'react';
import { FormInputProps, IOptionalAutoComplete, InnerFormInput } from './interfaces';
import { Autocomplete } from '@mui/material';
import { TextField } from './textFieldInput';
import FormControl from './formControl';

type TOption = { id: string; value: string };
interface IAutoComplete {
  options: any[];
}
type InnerAutoCompleteInputProps = InnerFormInput & IOptionalAutoComplete & IAutoComplete;
type AutoCompleteInputProps = FormInputProps & IOptionalAutoComplete & IAutoComplete;

const InnerAutoCompleteInput = forwardRef<React.MutableRefObject<HTMLInputElement>, InnerAutoCompleteInputProps>((props, ref) => {
  return (
    <Autocomplete
      id={`controlled-select-${props.label}`}
      options={props.options}
      getOptionLabel={option => option}
      ref={ref}
      disabled={props.isSubmitting}
      value={props.value || null}
      autoSelect={true}
      autoHighlight
      onChange={(event, value) => {
        props.onChange(value ?? null);
      }}
      renderInput={params => (
        <TextField
          {...params}
          value={props.value}
          error={Boolean(props.error)}
          label="Choose a state"
          InputProps={{ ...params.InputProps }}
          inputProps={{
            ...params.inputProps,
            autoComplete: props.autoComplete,
          }}
          sx={{ '& .MuiOutlinedInput-root': { p: '3px 4px 7.5px 6px' }, '& .MuiInputBase-input:-webkit-autofill': { height: '20px' } }}
        />
      )}
    />
  );
});

const AutoCompleteInput = (props: AutoCompleteInputProps) => {
  return <FormControl renderedComponent={<InnerAutoCompleteInput autoComplete={props.autoComplete} options={props.options} />} name={props.name} label={props.label} rules={props.rules} />;
};
export default AutoCompleteInput;
