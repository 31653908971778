// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventOptions = Record<string, any> & {
    category?: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    userId?: string;
  };
  
  type Action = 
    'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'view_cart'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'add_shipping_info'
  | 'add_payment_info'
  | 'purchase'
  |'select_content';

  // type Item = {
  //   item_id: string;
  //   item_name: string;
  //   item_list_id?: string;
  //   item_list_name?: string;
  //   discount?:number;
  //   coupon?: string;
  //   index?: number,
  //   item_brand?: string;
  //   item_category?:string;
  //   price?:number;
  //   quantity?:number;
  // }

  // type EventOption = {
  //   view_item_list: {
  //     items: Array<Item>
  //   }
  // }

  export function event(
    action: Action,
    { category, label, value, nonInteraction, userId, ...otherOptions }: EventOptions = {},
  ): void {
    if (!window.gtag) {
      return;
    }
  
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventOptions: Record<string, any> & {
      event_category?: string;
      event_label?: string;
      value?: number;
      non_interaction?: boolean;
      user_id?: string;
    } = { ...otherOptions };
  
    if (category !== undefined) {
      eventOptions.event_category = category;
    }
  
    if (label !== undefined) {
      eventOptions.event_label = label;
    }
  
    if (value !== undefined) {
      eventOptions.value = value;
    }
  
    if (nonInteraction !== undefined) {
      eventOptions.non_interaction = nonInteraction;
    }
  
    if (userId !== undefined) {
      eventOptions.user_id = userId;
    }
      
    window.gtag("event", action, eventOptions);
  }