import { forwardRef } from 'react';
import { InputAdornment } from '@mui/material';
import { TextField } from './textFieldInput';
import { FormInputProps, InnerFormInput } from './interfaces';
import FormControl from './formControl';
import { getValidatedDecimalNumber, removeDecimalPointWithoutDecimalNumber } from './lib';

const RateInnerInput = forwardRef<React.MutableRefObject<HTMLInputElement>, InnerFormInput>((props, ref) => {
  const { value, label, onChange, error, isSubmitting } = props;
  return (
    <TextField
      label={label}
      InputLabelProps={{ shrink: Boolean(value) }}
      onBlur={event => {
        onChange(removeDecimalPointWithoutDecimalNumber(event.target.value));
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      inputRef={ref}
      value={value || ''}
      error={Boolean(error)}
      disabled={isSubmitting}
      onChange={(event: { target: { value: string } }) => {
        onChange(getValidatedDecimalNumber(event.target.value, 3, 0, 100));
      }}
      inputProps={{
        options: {
          numeral: true,
          numeralPositiveOnly: true,
        },
      }}
      sx={{ '& .MuiOutlinedInput-input': { textAlign: 'end' } }}
    />
  );
});

type RateInputProps = FormInputProps;
const RateInput = (props: RateInputProps) => {
  return <FormControl renderedComponent={<RateInnerInput />} name={props.name} label={props.label} rules={props.rules} />;
};

export default RateInput;
