import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { FormInputProps, InnerFormInput } from './interfaces';
import FormControl from './formControl';
import { forwardRef } from 'react';

const InnerCheckboxInput = forwardRef((props: InnerFormInput, ref: React.MutableRefObject<HTMLButtonElement>) => {
  return (
    <label style={{ marginTop: '15px' }} className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
      <Checkbox
        ref={ref}
        checked={props.value}
        disabled={props.isSubmitting}
        onChange={props.onChange}
        id={`checkbox_${props.label}`}
        data-form-type="consent"
        sx={{
          color: props.errors ? '#d32f2f' : 'default',
          '&.Mui-checked': {
            color: props.errors ? '#d32f2f' : 'default',
          },
        }}
      />
      <span style={{ marginTop: '0.5rem', lineHeight: '150%' }}>{props.label}</span>
    </label>
  );
});

export const CheckboxInput = (props: FormInputProps) => {
  return <FormControl renderedComponent={<InnerCheckboxInput />} {...props} />;
};
export default CheckboxInput;
