import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';

type ClientIpState = {
  ip: string;
};

// type SetClientIpOnGetServerSidePropsAction = { payload: { context: }, type: string};
const clientIpSlice = createSlice({
  name: 'clientIp',
  initialState: { ip: null } as ClientIpState,
  reducers: {
    setClientIp(state, action) {
      state.ip = action.payload;
    },
    setClientIpOnGetServerSideProps(state, action: PayloadAction<GetServerSidePropsContext<ParsedUrlQuery, PreviewData>>) {
      try {
        if (!action?.payload?.req) return;
        const { req } = action.payload;
        const { headers, socket } = req;
        const getFirstIp = (header: string | string[]): string | undefined => (Array.isArray(header) ? header[0] : header?.split(', ')[0]);
        const ip = getFirstIp(headers['x-forwarded-for']) || getFirstIp(headers['x-real-ip']) || socket.remoteAddress;

        // console.log('IP:', {
        //   'x-real-ip': headers['x-real-ip'],
        //   'x-forwarded-for': headers['x-forwarded-for'],
        //   'socket.remoteAddress': socket.remoteAddress,
        //   'determined IP': ip,
        // });
        state.ip = ip || null;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    resetClientIp(state) {
      state.ip = null;
    },
  },
});
export const { setClientIp, setClientIpOnGetServerSideProps, resetClientIp } = clientIpSlice.actions;
export default clientIpSlice.reducer;
