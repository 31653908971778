import { emptySplitApi as api } from './emptyApi';

export const addTagTypes = [
  'getStations',
  'createStation',
  'getStation',
  'updateStation',
  'deleteStation',
  'getStationSchedules',
  'getBusinessHoursByStation',
  'updateStationSchedules',
  'stationScheduleBatch',
  'updateStationClosure',
  'getStationDaySchedule',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getStations: build.query<GetStationsApiResponse, GetStationsApiArg>({
        query: queryArg => ({
          url: '/station',
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ['getStations'],
      }),
      createStation: build.mutation<CreateStationApiResponse, CreateStationApiArg>({
        query: queryArg => ({
          url: '/station',
          method: 'POST',
          data: queryArg.createStationRequest,
        }),
        invalidatesTags: ['createStation'],
      }),
      getStation: build.query<GetStationApiResponse, GetStationApiArg>({
        query: queryArg => ({ url: `/station/${queryArg.id}` }),
        providesTags: ['getStation'],
      }),
      updateStation: build.mutation<UpdateStationApiResponse, UpdateStationApiArg>({
        query: queryArg => ({
          url: `/station/${queryArg.id}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['updateStation'],
      }),
      deleteStation: build.mutation<DeleteStationApiResponse, DeleteStationApiArg>({
        query: queryArg => ({
          url: `/station/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['deleteStation'],
      }),
      getStationSchedules: build.query<GetStationSchedulesApiResponse, GetStationSchedulesApiArg>({
        query: queryArg => ({
          url: '/station/schedules',
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
            fromDate: queryArg.fromDate,
            toDate: queryArg.toDate,
          },
        }),
        providesTags: ['getStationSchedules'],
      }),
      getBusinessHoursByStation: build.query<GetBusinessHoursByStationApiResponse, GetBusinessHoursByStationApiArg>({
        query: queryArg => ({ url: `/station/hours/${queryArg.id}` }),
        providesTags: ['getBusinessHoursByStation'],
      }),
      updateStationSchedules: build.mutation<UpdateStationSchedulesApiResponse, UpdateStationSchedulesApiArg>({
        query: queryArg => ({
          url: `/station/schedules/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.stationSchedulePutRequest,
        }),
        invalidatesTags: ['updateStationSchedules'],
      }),
      stationScheduleBatch: build.mutation<StationScheduleBatchApiResponse, StationScheduleBatchApiArg>({
        query: queryArg => ({
          url: '/station/stationScheduleBatch',
          method: 'POST',
          data: queryArg.stationScheduleBatchRequest,
        }),
        invalidatesTags: ['stationScheduleBatch'],
      }),
      updateStationClosure: build.mutation<UpdateStationClosureApiResponse, UpdateStationClosureApiArg>({
        query: queryArg => ({
          url: '/station/closure',
          method: 'POST',
          data: queryArg.stationClosureRequest,
        }),
        invalidatesTags: ['updateStationClosure'],
      }),
      getStationDaySchedule: build.query<GetStationDayScheduleApiResponse, GetStationDayScheduleApiArg>({
        query: queryArg => ({
          url: '/station/schedule',
          params: { fromDate: queryArg.fromDate, urlPath: queryArg.urlPath },
        }),
        providesTags: ['getStationDaySchedule'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeStationApi };
export type GetStationsApiResponse = /** status 200  */ StationsResponse;
export type GetStationsApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: string;
  status?: number;
};
export type CreateStationApiResponse = /** status 200  */ SingleStationResponse;
export type CreateStationApiArg = {
  createStationRequest: CreateStationRequest;
};
export type GetStationApiResponse = /** status 200  */ SingleStationResponse;
export type GetStationApiArg = {
  id: string;
};
export type UpdateStationApiResponse = /** status 200  */ SingleStationResponse;
export type UpdateStationApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteStationApiResponse = /** status 200  */ VoidResponse;
export type DeleteStationApiArg = {
  id: string;
};
export type GetStationSchedulesApiResponse = /** status 200  */ StationScheduleResponse;
export type GetStationSchedulesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: string;
  status?: number;
  fromDate?: string;
  toDate?: string;
};
export type GetBusinessHoursByStationApiResponse = /** status 200  */ SingleBusinessHourResponse;
export type GetBusinessHoursByStationApiArg = {
  id: string;
};
export type UpdateStationSchedulesApiResponse = /** status 200  */ UpdatedStationSchedulesResponse;
export type UpdateStationSchedulesApiArg = {
  id: string;
  stationSchedulePutRequest: StationSchedulePutRequest;
};
export type StationScheduleBatchApiResponse = /** status 200  */ VoidResponse;
export type StationScheduleBatchApiArg = {
  stationScheduleBatchRequest: StationScheduleBatchRequest;
};
export type UpdateStationClosureApiResponse = /** status 200  */ UpdatedStationSchedulesResponse;
export type UpdateStationClosureApiArg = {
  stationClosureRequest: StationClosureRequest;
};
export type GetStationDayScheduleApiResponse = /** status 200  */ StationScheduleForDayResponse;
export type GetStationDayScheduleApiArg = {
  fromDate: string;

  /** eg. espa/schedule or kbs/schedule */
  urlPath: string;
};
export type BusinessHourMeta = {
  dayNameType?: number;
  businessStart?: number;
  businessEnd?: number;
};
export type StationMeta = {
  reservationTreatmentInterval?: number;
  preBusinessStartHours?: number;
  reservationTreatmentTimeslots?: string[];
  postBusinessEndHours?: number;
  businessHours?: BusinessHourMeta[];
};
export type BusinessHour = {
  stationId: string;
  dayNameType: number;
  businessStart?: number;
  businessEnd?: number;
};
export type SingleStationDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  locationId: string;
  name: string;
  tags?: string;
  meta?: StationMeta;
  sequence: number;
  status: number;
  BusinessHours: BusinessHour[];
};
export type StationsResponse = {
  code?: number;
  messages?: string[];
  result?: SingleStationDto[];
};
export type SingleStationResponse = {
  code?: number;
  messages?: string[];
  result?: SingleStationDto;
};
export type CreateStationRequest = {
  locationId: string;
  name: string;

  /** "all" or "espa", "kbs", "acupressure" */
  tags?: string;
  sequence: number;
  validFrom: string;

  /** In minutes */
  reservationTreatmentInterval: number;

  /** In minutes|type */
  reservationTreatmentTimeslots: any[];

  /** In minutes */
  preBusinessStartHours: number;

  /** In minutes */
  postBusinessEndHours: number;
};
export type PatchOperation = 'add' | 'replace' | 'test' | 'remove' | 'move' | 'copy';
export type Patch = {
  op: PatchOperation;

  /** Path */
  path: string;

  /** Value */
  value?: object;

  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type ScheduleCalendar = {
  date?: string;
  weekday?: number;
};
export type SingleStationSchedule = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  calendarId: string;
  stationId: string;
  description?: string;
  businessStart?: string;
  businessEnd?: string;
  status: number;
  Calendar?: ScheduleCalendar;
};
export type StationSchedule = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  locationId: string;
  name: string;
  tags?: string;
  meta?: StationMeta;
  sequence: number;
  status: number;
  BusinessHours: BusinessHour[];
  StationSchedule: SingleStationSchedule[];
};
export type StationScheduleResponse = {
  code?: number;
  messages?: string[];
  result?: StationSchedule[];
};
export type SingleBusinessHourResponse = {
  code?: number;
  messages?: string[];
  result?: BusinessHour[];
};
export type UpdatedStationSchedulesResponse = {
  code?: number;
  messages?: string[];
  result?: SingleStationSchedule[];
};
export type StationSchedulePutRequest = {
  /** The start date for updating schedules. If provided, schedules will be updated from this date onward. */
  fromDate?: string;

  /** If null or undefined, the default is set as 6 months from the fromDate. If the same date as fromDate, only schedules for that specific day will be updated. */
  toDate?: string;

  /** Set to true when updating only station schedules without updating business hours of station. Set to false if updating both station hour and schedules. */
  isThisDayOnly: boolean;

  /** Set to true when updating station schedules that have (closed, maintenance and etc) description to regular business hours (where description is null). Set to false if updating time for regular hours. */
  isOverride: boolean;
  description: string;
  businessHours: BusinessHour[];
};
export type StationScheduleBatchRequest = {
  from?: string;
  to?: string;
  stationIds?: string[];
};
export type StationClosureRequest = {
  date: string;
  description: string;
  stationIds: string[];
};
export type StationScheduleForDay = {
  stationId: string;
  businessStart: string;
  businessEnd: string;
};
export type StationScheduleForDayDto = {
  minimumBusinessHourStart?: string;
  maximumBusinessHourEnd?: string;
  beforeBusinessHourStart?: string;
  afterBusinessHourEnd?: string;
  businessSchedule: StationScheduleForDay[];
};
export type StationScheduleForDayResponse = {
  code?: number;
  messages?: string[];
  result?: StationScheduleForDayDto;
};
export const {
  useGetStationsQuery,
  useCreateStationMutation,
  useGetStationQuery,
  useUpdateStationMutation,
  useDeleteStationMutation,
  useGetStationSchedulesQuery,
  useGetBusinessHoursByStationQuery,
  useUpdateStationSchedulesMutation,
  useStationScheduleBatchMutation,
  useUpdateStationClosureMutation,
  useGetStationDayScheduleQuery,
} = injectedRtkApi;
