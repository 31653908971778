import { forwardRef } from 'react';
import { TextField as MuiTextField, withStyles } from '@mui/material';
import { styled } from '@mui/system';
import { FormInputProps, IOptionalAutoComplete, InnerFormInput } from './interfaces';
import FormControl from './formControl';
import React from 'react';

// class Name을 도메인별로 나눈다. ? sx로 prop을 넘겨준다.
//
export const MuiInputLabelRootCSS = {
  zIndex: 0,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  top: '-7px',
};

export const TextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': { height: '40px' },
  '& .MuiInputLabel-root': MuiInputLabelRootCSS,
  '& .MuiInputLabel-shrink': { fontSize: '12px', top: '0px', zIndex: 1 },
  '& .MuiFormHelperText-root': { display: 'flex', paddingLeft: '0px', justifyContent: 'center', alignItems: 'center', gap: '10px', textWrap: 'pretty' },
  // '& .MuiInputBase-input:-webkit-autofill': { height: '7px' },
  '& .MuiFormHelperText-root.Mui-error': { justifyContent: 'flex-start' },
});
interface IMaxLength {
  maxLength?: number;
}
interface ITextFieldInputProps extends IOptionalAutoComplete, IMaxLength {
  overrideSx?: React.CSSProperties;
  overrideHelperTextSx?: React.CSSProperties;
}
export type InnerTextFieldInputProps = InnerFormInput & ITextFieldInputProps & { renderComponentName: JSX.Element };
export type TextFieldInputProps = FormInputProps & ITextFieldInputProps;

const InnerTextFieldInput = forwardRef((props: InnerTextFieldInputProps, ref: React.MutableRefObject<HTMLInputElement>) => {
  return (
    <TextField
      inputRef={ref}
      value={props.value || ''}
      onBlur={e => props.onChange(e.target.value.toString().trim())}
      InputLabelProps={{ shrink: Boolean(props.value) }}
      onChange={props.onChange}
      disabled={props.isSubmitting}
      label={props.label}
      error={Boolean(props.error)}
      autoComplete={props.autoComplete}
      inputProps={{ maxLength: props.maxLength }}
      helperText={props.error?.message}
      sx={{
        ...props.overrideSx,
        '& .MuiFormHelperText-root': {
          ...props.overrideHelperTextSx,
        },
      }}
    />
  );
});
export const TextFieldInput = (props: TextFieldInputProps) => {
  return (
    <FormControl
      renderedComponent={<InnerTextFieldInput overrideSx={props.overrideSx} overrideHelperTextSx={props.overrideHelperTextSx} autoComplete={props.autoComplete} maxLength={props.maxLength} />}
      name={props.name}
      label={props.label}
      rules={props.rules}
    />
  );
};

export default TextFieldInput;
