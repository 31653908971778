'use client';

import React from 'react';
import { SessionProvider, useSession } from 'next-auth/react';
import authConfig from '@core/configs/auth';

export const Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SessionProvider>
      <SetAccessTokenLocalStorage>
        {/* <AuthProvider> */}
        {children}
        {/* </AuthProvider> */}
      </SetAccessTokenLocalStorage>
    </SessionProvider>
  );
};
const SetAccessTokenLocalStorage = ({ children }: { children: React.ReactNode }) => {
  const { data: session } = useSession();

  React.useEffect(() => {
    //TODO: check expire time to remove localStorage &  log out mutation with email.
    // if(session.expires){

    // }
    const sessionAccessToken = session?.user?.token;
    if (sessionAccessToken) localStorage.setItem(authConfig.storageTokenKeyName, sessionAccessToken);
  }, [session]);

  return <>{children}</>;
};

export default Provider;
