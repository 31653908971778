export * from './cleaveInput';
export const getValidatedDecimalNumber = (value: string, decimalPoint: number, min = 0, max = 100) => {
  if (value.includes('.') && (value.split('.')?.at(-1) ?? []).length > decimalPoint) value = value?.slice(0, -1);

  if (value.length == 1 && value.includes('.')) {
    return '0.';
  }
  const number = +value;
  if (isNaN(number) || number < min) {
    return '0';
  }
  if (value.length == 2 && value.at(0) === '0') {
    return value.at(1) === '.' ? '0.' : value.slice(1);
  }

  if (number > max) {
    return max.toString();
  }
  return value;
};
export const removeDecimalPointWithoutDecimalNumber = (value: string) => {
  if (value.includes('.') && value.split('.').at(-1) === '') {
    value = value.slice(0, -1);
    return +value;
  }
  return value;
};

export const getPriceInteger = (numString: string): number => {
  const digit = numString.length;
  return +(+numString * 100).toPrecision(digit);
};

export const trimStringFormData = <TCreate>(formData: TCreate) => {
  Object.entries(formData).forEach(([key, value]) => {
    if (typeof value === 'string') {
      
      formData[key] = value.toString().trim();
    }
  });
};
