import React, { CSSProperties, forwardRef } from 'react';
import { TextField } from './textFieldInput';
import { CleaveOptions } from 'cleave.js/options';
import FormControl from './formControl';
import { FormInputProps, InnerFormInput } from './interfaces';
import { CleaveInput } from './lib/cleaveInput';
import { InputBaseComponentProps, SxProps } from '@mui/material';

interface ICleaveTextFieldInput {
  cleaveOptions: CleaveOptions;
  extraCleaveProps?: InputBaseComponentProps;
  overrideHelperTextSx?: CSSProperties;
  overrideSx?: SxProps;
  placeholder?: string;
  hasErrorMessage?: boolean;
}
interface InnerCleaveTextFieldInputProps extends InnerFormInput, ICleaveTextFieldInput {}
interface CleaveTextFieldInputProps extends FormInputProps, ICleaveTextFieldInput {}

const InnerCleaveTextFieldInput = forwardRef<React.MutableRefObject<HTMLInputElement>, InnerCleaveTextFieldInputProps>((props, ref) => {
  const { value, onChange } = props;
  const hasErrorMessageOnHelperText = props.hasErrorMessage ?? true;
  return (
    <TextField
      label={props.label}
      inputRef={ref}
      disabled={props.isSubmitting}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
      inputMode="numeric"
      error={Boolean(props.error)}
      helperText={hasErrorMessageOnHelperText ? props.error?.message : null}
      InputProps={{ inputComponent: CleaveInput }}
      inputProps={{
        value,
        onChange,
        ref,
        ...props.extraCleaveProps,
        options: {
          ...props.cleaveOptions,
        },
      }}
      sx={{
        '& .MuiFormHelperText-root': {
          ...props.overrideHelperTextSx,
        },
        ...props.overrideSx,
        // '& .MuiInputBase-input:-webkit-autofill': { height: '7px' }
      }}
    />
  );
});

export const CleaveTextFieldInput = (props: CleaveTextFieldInputProps) => {
  return (
    <FormControl
      renderedComponent={
        <InnerCleaveTextFieldInput
          overrideSx={props.overrideSx}
          overrideHelperTextSx={props.overrideHelperTextSx}
          extraCleaveProps={props.extraCleaveProps}
          cleaveOptions={props.cleaveOptions}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          hasErrorMessage={props.hasErrorMessage}
        />
      }
      name={props.name}
      label={props.label}
      rules={props.rules}
    />
  );
};

export default CleaveTextFieldInput;
