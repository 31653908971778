import { emptySplitApi as api } from './emptyApi';

export const addTagTypes = ['getTherapistsSchedules', 'getEmployeeSchedule', 'updateTherapistSchedule', 'employeeScheduleBatch', 'updateTherapistVacation', 'getTherapistsBySequence', 'patchTherapistSequence', 'updateTherapistSequence'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getTherapistsSchedules: build.query<GetTherapistsSchedulesApiResponse, GetTherapistsSchedulesApiArg>({
        query: queryArg => ({
          url: '/employeeSchedule/therapistsSchedules',
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            departmentId: queryArg.departmentId,
            roleId: queryArg.roleId,
            employeeIds: queryArg.employeeIds,
            serviceGroup: queryArg.serviceGroup,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
            fromDate: queryArg.fromDate,
            toDate: queryArg.toDate,
          },
        }),
        providesTags: ['getTherapistsSchedules'],
      }),
      getEmployeeSchedule: build.query<GetEmployeeScheduleApiResponse, GetEmployeeScheduleApiArg>({
        query: queryArg => ({ url: `/employeeSchedule/${queryArg.id}` }),
        providesTags: ['getEmployeeSchedule'],
      }),
      updateTherapistSchedule: build.mutation<UpdateTherapistScheduleApiResponse, UpdateTherapistScheduleApiArg>({
        query: queryArg => ({
          url: `/employeeSchedule/therapistSchedule/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.employeeSchedulePutRequest,
        }),
        invalidatesTags: ['updateTherapistSchedule'],
      }),
      employeeScheduleBatch: build.mutation<EmployeeScheduleBatchApiResponse, EmployeeScheduleBatchApiArg>({
        query: queryArg => ({
          url: '/employeeSchedule/employeeScheduleBatch',
          method: 'POST',
          data: queryArg.employeeScheduleBatchRequest,
        }),
        invalidatesTags: ['employeeScheduleBatch'],
      }),
      updateTherapistVacation: build.mutation<UpdateTherapistVacationApiResponse, UpdateTherapistVacationApiArg>({
        query: queryArg => ({
          url: `/employeeSchedule/therapistVacation/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.therapistVacationRequest,
        }),
        invalidatesTags: ['updateTherapistVacation'],
      }),
      getTherapistsBySequence: build.query<GetTherapistsBySequenceApiResponse, GetTherapistsBySequenceApiArg>({
        query: queryArg => ({
          url: `/employeeSchedule/therapistSequence/${queryArg.date}/${queryArg.stationId}`,
        }),
        providesTags: ['getTherapistsBySequence'],
      }),
      patchTherapistSequence: build.mutation<PatchTherapistSequenceApiResponse, PatchTherapistSequenceApiArg>({
        query: queryArg => ({
          url: `/employeeSchedule/therapistSequence/${queryArg.date}/${queryArg.stationId}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['patchTherapistSequence'],
      }),
      updateTherapistSequence: build.mutation<UpdateTherapistSequenceApiResponse, UpdateTherapistSequenceApiArg>({
        query: queryArg => ({
          url: '/employeeSchedule/therapistSequence',
          method: 'POST',
          data: queryArg.updateTherapistsSequenceRequest,
        }),
        invalidatesTags: ['updateTherapistSequence'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeEmployeeApi };
export type GetTherapistsSchedulesApiResponse = /** status 200  */ TherapistScheduleResponse;
export type GetTherapistsSchedulesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  departmentId?: string;
  roleId?: string;

  /** Converts an array of employeeIds to JSON.stringify(['EP234567891234', 'EP123456789111']) when sending a request. */
  employeeIds?: string;

  /** Converts an array of employeeIds to JSON.stringify(['ESPA', 'KBS', 'Acupressure']) when sending a request. */
  serviceGroup?: string;
  sortBy?: string;
  status?: number;
  fromDate?: string;
  toDate?: string;
};
export type GetEmployeeScheduleApiResponse = /** status 200  */ SingleEmployeeScheduleResponse;
export type GetEmployeeScheduleApiArg = {
  id: string;
};
export type UpdateTherapistScheduleApiResponse = /** status 200  */ UpdateTherapistSchedulesResponse;
export type UpdateTherapistScheduleApiArg = {
  id: string;
  employeeSchedulePutRequest: EmployeeSchedulePutRequest;
};
export type EmployeeScheduleBatchApiResponse = /** status 200  */ VoidResponse;
export type EmployeeScheduleBatchApiArg = {
  employeeScheduleBatchRequest: EmployeeScheduleBatchRequest;
};
export type UpdateTherapistVacationApiResponse = /** status 200  */ UpdateTherapistVacationResponse;
export type UpdateTherapistVacationApiArg = {
  id: string;
  therapistVacationRequest: TherapistVacationRequest;
};
export type GetTherapistsBySequenceApiResponse = /** status 200  */ TherapistsBySequenceResponse;
export type GetTherapistsBySequenceApiArg = {
  stationId: string;
  date: string;
};
export type PatchTherapistSequenceApiResponse = /** status 200  */ TherapistsBySequenceResponse;
export type PatchTherapistSequenceApiArg = {
  stationId: string;
  date: string;
  patchRequest: PatchRequest;
};
export type UpdateTherapistSequenceApiResponse = /** status 200  */ VoidResponse;
export type UpdateTherapistSequenceApiArg = {
  updateTherapistsSequenceRequest: UpdateTherapistsSequenceRequest;
};
export type TherapistProfile = {
  gender?: string;
  fullName: string;
};
export type TherapistRole = {
  name: string;
};
export type EmployeeSchedule = {
  employeeId: string;
  dayNameType: number;
  workStart?: number;
  workEnd?: number;
  lunchStart?: number;
  lunchEnd?: number;
};
export type ScheduleCalendar = {
  date?: string;
  weekday?: number;
};
export type SingleTherapistSchedule = {
  employeeId: string;
  calendarId: string;
  workStart?: string;
  workEnd?: string;
  lunchStart?: string;
  lunchEnd?: string;
  description?: string;
  status: number;
  Calendar?: ScheduleCalendar;
};
export type TherapistSchedules = {
  id: string;
  weeklyWorkHours: number;
  Profile: TherapistProfile;
  Role?: TherapistRole;
  EmployeeSchedules: EmployeeSchedule[];
  TherapistSchedules?: SingleTherapistSchedule[];
};
export type TherapistScheduleResponse = {
  code?: number;
  messages?: string[];
  result?: TherapistSchedules[];
};
export type SingleEmployeeScheduleResponse = {
  code?: number;
  messages?: string[];
  result?: EmployeeSchedule[];
};
export type UpdateTherapistSchedule = {
  EmployeeSchedules: EmployeeSchedule[];
  TherapistSchedules: SingleTherapistSchedule[];
};
export type UpdateTherapistSchedulesResponse = {
  code?: number;
  messages?: string[];
  result?: UpdateTherapistSchedule;
};
export type EmployeeSchedulePutRequest = {
  /** The start date for updating schedules. If provided, schedules will be updated from this date onward. */
  fromDate?: string;

  /** If null or undefined, the default is set as 6months from the fromDate. If the same date as fromDate, only schedules for that specific day will be updated. */
  toDate?: string;

  /** Set to true when updating only therapist schedules without updating employee schedules. Set to false if updating both employee and therapist schedules. */
  isThisDayOnly?: boolean;
  employeeSchedules: EmployeeSchedule[];
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type EmployeeScheduleBatchRequest = {
  from?: string;
  to?: string;
  employeeIds?: string[];
  roleIds?: string[];
};
export type UpdateTherapistVacationResponse = {
  code?: number;
  messages?: string[];
  result?: SingleTherapistSchedule[];
};
export type TherapistVacationRequest = {
  fromDate?: string;
  toDate?: string;
  description?: string;
};
export type TherapistsBySequence = {
  id: string;
  fullName: string;
  gender: string;
  rank: number;
  validFrom: string;
};
export type TherapistsBySequenceResponse = {
  code?: number;
  messages?: string[];
  result?: TherapistsBySequence[];
};
export type PatchOperation = 'add' | 'replace' | 'test' | 'remove' | 'move' | 'copy';
export type Patch = {
  op: PatchOperation;

  /** Path */
  path: string;

  /** Value */
  value?: object;

  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type UpdateTherapistsSequenceRequest = {
  fromDate: string;
  toDate?: string | null;
  stationIds?: string | null;
  employeeIds?: string | null;
};
export const {
  useGetTherapistsSchedulesQuery,
  useGetEmployeeScheduleQuery,
  useUpdateTherapistScheduleMutation,
  useEmployeeScheduleBatchMutation,
  useUpdateTherapistVacationMutation,
  useGetTherapistsBySequenceQuery,
  usePatchTherapistSequenceMutation,
  useUpdateTherapistSequenceMutation,
} = injectedRtkApi;
