import { emptySplitApi as api } from './emptyApi';

export const addTagTypes = [
  'postAppointmentService',
  'postAppointmentBlock',
  'postReservation',
  'patchReservation',
  'patchAppointmentService',
  'patchAppointmentBlock',
  'getCartForReservationTreatment',
  'renewTimeslotForReservationTreatment',
  'releaseTimeslotForReservationTreatment',
  'postTreatmentCartNumberOfGuests',
  'addGuest',
  'updateTreatmentCart',
  'deleteCartForReservationTreatment',
  'postTreatmentCartAdd',
  'createIncompleteOrderForReservationTreatment',
  'updateIncompleteOrderForReservationTreatment',
  'confirmOrderForReservationTreatment',
  'placeOrderForReservationTreatment',
  'patchPlaceOrderForReservationTreatment',
  'getStaffs',
  'getSchedulesByResource',
  'getAllSchedulesByResource',
  'updateAppointment',
  'getAppointmentNotes',
  'createAppointmentNote',
  'updateAppointmentNote',
  'deleteAppointmentNote',
  'getAvailableDateslots',
  'getPrepareEditAppointment',
  'getPrepareNewAppointment',
  'getAvailableTherapists',
  'getServiceTimeslots',
  'getTreatmentAvailableTimeslots',
  'getAvailableTimeslotsByProduct',
  'getOnlineCategories',
  'getOnlineCategory',
  'updateOnlineCategory',
  'deleteOnlineCategory',
  'createOnlineCategory',
  'getOnlineProducts',
  'getProductsByServiceGroup',
  'getOnlineProduct',
  'updateOnlineProductDetail',
  'deleteOnlineProduct',
  'getRelatedOnlineProducts',
  'updateRelatedOnlineProducts',
  'getProductPackageList',
  'createOnlineProduct',
  'updateOnlineTags',
  'getOnlineItemsBySlug',
  'treatmentOrderList',
  'treatmentOrder',
  'confirmedTreatmentOrder',
  'cancelTreatmentOrder',
  'patchPartialCancelTreatmentOrder',
  'postOrderCancellation',
  'postOrderRefund',
  'treatmentOrderResend',
  'updateVault',
  'editProfile',
  'emitAppointments',
  'getAppointments',
  'getStationsForReservationTreatment',
  'getStationsForTherapistSequence',
  'getPaymentLines',
  'getRelatedAppointmentsForStatusChange',
  'getRelatedAppointmentsForCancellation',
  'getAppointmentsForRefund',
  'updateAppointmentsForStatus',
  'getGroupAppointments',
  'addGroupAppointment',
  'removeGroupAppointment',
  'timeslotBatch',
  'checkOutAppointments',
  'cancelledAppointments',
  'voidCheckedOutOrder',
  'treatmentOrderConfirmationBatch',
  'treatmentOrderSurveyBatch',
  'confirmAppointments',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      postAppointmentService: build.mutation<PostAppointmentServiceApiResponse, PostAppointmentServiceApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.stationId}/${queryArg.calendarId}/${queryArg.timeslotId}/${queryArg.productId}/service`,
          method: 'POST',
          data: queryArg.appointmentRequest,
        }),
        invalidatesTags: ['postAppointmentService'],
      }),
      postAppointmentBlock: build.mutation<PostAppointmentBlockApiResponse, PostAppointmentBlockApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.stationId}/${queryArg.calendarId}/${queryArg.startTimeslotId}/${queryArg.endTimeslotId}/block`,
          method: 'POST',
          data: queryArg.appointmentRequest,
        }),
        invalidatesTags: ['postAppointmentBlock'],
      }),
      postReservation: build.mutation<PostReservationApiResponse, PostReservationApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/reservation/${queryArg.timeslotId}/${queryArg.productId}`,
          method: 'POST',
          data: queryArg.body,
          params: {
            ttl: queryArg.ttl,
            override: queryArg['override'],
            cartItemId: queryArg.cartItemId,
          },
        }),
        invalidatesTags: ['postReservation'],
      }),
      patchReservation: build.mutation<PatchReservationApiResponse, PatchReservationApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/reservation/${queryArg.cartItemId}/${queryArg.productId}/${queryArg.appointmentIds}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
          params: {
            ttl: queryArg.ttl,
            override: queryArg['override'],
            isCompleted: queryArg.isCompleted,
          },
        }),
        invalidatesTags: ['patchReservation'],
      }),
      patchAppointmentService: build.mutation<PatchAppointmentServiceApiResponse, PatchAppointmentServiceApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.appointmentIds}/service`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['patchAppointmentService'],
      }),
      patchAppointmentBlock: build.mutation<PatchAppointmentBlockApiResponse, PatchAppointmentBlockApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.id}/block`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['patchAppointmentBlock'],
      }),
      getCartForReservationTreatment: build.query<GetCartForReservationTreatmentApiResponse, GetCartForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/cart',
          params: {
            appointmentIds: queryArg.appointmentIds,
            customerIds: queryArg.customerIds,
            isRebook: queryArg.isRebook,
            sessionId: queryArg.sessionId,
            cartItemId: queryArg.cartItemId,
          },
        }),
        providesTags: ['getCartForReservationTreatment'],
      }),
      renewTimeslotForReservationTreatment: build.mutation<RenewTimeslotForReservationTreatmentApiResponse, RenewTimeslotForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/${queryArg.id}/renew`,
          method: 'PATCH',
          params: { ttl: queryArg.ttl, override: queryArg['override'] },
        }),
        invalidatesTags: ['renewTimeslotForReservationTreatment'],
      }),
      releaseTimeslotForReservationTreatment: build.mutation<ReleaseTimeslotForReservationTreatmentApiResponse, ReleaseTimeslotForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/${queryArg.id}/release`,
          method: 'DELETE',
        }),
        invalidatesTags: ['releaseTimeslotForReservationTreatment'],
      }),
      postTreatmentCartNumberOfGuests: build.mutation<PostTreatmentCartNumberOfGuestsApiResponse, PostTreatmentCartNumberOfGuestsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/${queryArg.numberOfGuests}`,
          method: 'POST',
          data: queryArg.body,
        }),
        invalidatesTags: ['postTreatmentCartNumberOfGuests'],
      }),
      addGuest: build.mutation<AddGuestApiResponse, AddGuestApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/guest/${queryArg.cartItemId}`,
          method: 'POST',
          data: queryArg.guestDto,
        }),
        invalidatesTags: ['addGuest'],
      }),
      updateTreatmentCart: build.mutation<UpdateTreatmentCartApiResponse, UpdateTreatmentCartApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/${queryArg.cartItemId}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['updateTreatmentCart'],
      }),
      deleteCartForReservationTreatment: build.mutation<DeleteCartForReservationTreatmentApiResponse, DeleteCartForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/${queryArg.cartItemId}`,
          method: 'DELETE',
          params: {
            appointmentIds: queryArg.appointmentIds,
            customerIds: queryArg.customerIds,
            isRebook: queryArg.isRebook,
            override: queryArg['override'],
          },
        }),
        invalidatesTags: ['deleteCartForReservationTreatment'],
      }),
      postTreatmentCartAdd: build.mutation<PostTreatmentCartAddApiResponse, PostTreatmentCartAddApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/cart/${queryArg.cartItemId}/${queryArg.productId}/${queryArg.timeslotId}`,
          method: 'POST',
          data: queryArg.body,
          params: { ttl: queryArg.ttl, override: queryArg['override'] },
        }),
        invalidatesTags: ['postTreatmentCartAdd'],
      }),
      createIncompleteOrderForReservationTreatment: build.mutation<CreateIncompleteOrderForReservationTreatmentApiResponse, CreateIncompleteOrderForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/incomplete',
          method: 'POST',
          data: queryArg.treatmentCreateIncompleteOrderRequest,
        }),
        invalidatesTags: ['createIncompleteOrderForReservationTreatment'],
      }),
      updateIncompleteOrderForReservationTreatment: build.mutation<UpdateIncompleteOrderForReservationTreatmentApiResponse, UpdateIncompleteOrderForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/incomplete/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.treatmentUpdateIncompleteOrderRequest,
        }),
        invalidatesTags: ['updateIncompleteOrderForReservationTreatment'],
      }),
      confirmOrderForReservationTreatment: build.mutation<ConfirmOrderForReservationTreatmentApiResponse, ConfirmOrderForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/confirm/${queryArg.id}`,
          method: 'POST',
          data: queryArg.treatmentConfirmOrderRequest,
        }),
        invalidatesTags: ['confirmOrderForReservationTreatment'],
      }),
      placeOrderForReservationTreatment: build.mutation<PlaceOrderForReservationTreatmentApiResponse, PlaceOrderForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/placeOrder',
          method: 'POST',
          data: queryArg.placeOrderRequest,
        }),
        invalidatesTags: ['placeOrderForReservationTreatment'],
      }),
      patchPlaceOrderForReservationTreatment: build.mutation<PatchPlaceOrderForReservationTreatmentApiResponse, PatchPlaceOrderForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/placeOrder/${queryArg.cartItemId}`,
          method: 'PATCH',
          data: queryArg.patchPlaceOrderRequest,
        }),
        invalidatesTags: ['patchPlaceOrderForReservationTreatment'],
      }),
      getStaffs: build.query<GetStaffsApiResponse, GetStaffsApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/staffs',
          params: { date: queryArg.date },
        }),
        providesTags: ['getStaffs'],
      }),
      getSchedulesByResource: build.query<GetSchedulesByResourceApiResponse, GetSchedulesByResourceApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/schedulesByResource',
          params: {
            resourceType: queryArg.resourceType,
            date: queryArg.date,
            stationIds: queryArg.stationIds,
            therapistIds: queryArg.therapistIds,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            therapistId: queryArg.therapistId,
            filterType: queryArg.filterType,
            urlPath: queryArg.urlPath,
          },
        }),
        providesTags: ['getSchedulesByResource'],
      }),
      getAllSchedulesByResource: build.query<GetAllSchedulesByResourceApiResponse, GetAllSchedulesByResourceApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/allSchedulesByResource',
          params: {
            resourceType: queryArg.resourceType,
            date: queryArg.date,
            stationIds: queryArg.stationIds,
            therapistIds: queryArg.therapistIds,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            therapistId: queryArg.therapistId,
            filterType: queryArg.filterType,
            urlPath: queryArg.urlPath,
          },
        }),
        providesTags: ['getAllSchedulesByResource'],
      }),
      updateAppointment: build.mutation<UpdateAppointmentApiResponse, UpdateAppointmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.updateAppointmentPatchRequest,
        }),
        invalidatesTags: ['updateAppointment'],
      }),
      getAppointmentNotes: build.query<GetAppointmentNotesApiResponse, GetAppointmentNotesApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/notes/${queryArg.appointmentId}`,
        }),
        providesTags: ['getAppointmentNotes'],
      }),
      createAppointmentNote: build.mutation<CreateAppointmentNoteApiResponse, CreateAppointmentNoteApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/note',
          method: 'POST',
          data: queryArg.noteRequest,
        }),
        invalidatesTags: ['createAppointmentNote'],
      }),
      updateAppointmentNote: build.mutation<UpdateAppointmentNoteApiResponse, UpdateAppointmentNoteApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/note/${queryArg.id}`,
          method: 'PATCH',
          data: queryArg.noteRequest,
        }),
        invalidatesTags: ['updateAppointmentNote'],
      }),
      deleteAppointmentNote: build.mutation<DeleteAppointmentNoteApiResponse, DeleteAppointmentNoteApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/note/${queryArg.id}`,
          method: 'DELETE',
          params: {
            appointmentId: queryArg.appointmentId,
            message: queryArg.message,
            orderId: queryArg.orderId,
            cartItemId: queryArg.cartItemId,
          },
        }),
        invalidatesTags: ['deleteAppointmentNote'],
      }),
      getAvailableDateslots: build.query<GetAvailableDateslotsApiResponse, GetAvailableDateslotsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/availableDateslots/${queryArg.cartItemId}/${queryArg.productId}`,
          params: {
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            stationId: queryArg.stationId,
            customerIds: queryArg.customerIds,
            serviceIds: queryArg.serviceIds,
            preferenceGenders: queryArg.preferenceGenders,
          },
        }),
        providesTags: ['getAvailableDateslots'],
      }),
      getPrepareEditAppointment: build.query<GetPrepareEditAppointmentApiResponse, GetPrepareEditAppointmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.appointmentId}/preparation`,
        }),
        providesTags: ['getPrepareEditAppointment'],
      }),
      getPrepareNewAppointment: build.query<GetPrepareNewAppointmentApiResponse, GetPrepareNewAppointmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.stationId}/${queryArg.calendarId}/${queryArg.appointmentType}/preparation`,
          params: {
            serviceEndAt: queryArg.serviceEndAt,
            serviceStartAt: queryArg.serviceStartAt,
            therapistId: queryArg.therapistId,
          },
        }),
        providesTags: ['getPrepareNewAppointment'],
      }),
      getAvailableTherapists: build.query<GetAvailableTherapistsApiResponse, GetAvailableTherapistsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointment/${queryArg.calendarId}/${queryArg.productId}/${queryArg.timeslotId}/${queryArg.duration}/availableTherapist`,
          params: { excludeAppointmentIds: queryArg.excludeAppointmentIds },
        }),
        providesTags: ['getAvailableTherapists'],
      }),
      getServiceTimeslots: build.query<GetServiceTimeslotsApiResponse, GetServiceTimeslotsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/serviceTimeslots/${queryArg.calendarId}`,
          params: { stationIds: queryArg.stationIds },
        }),
        providesTags: ['getServiceTimeslots'],
      }),
      getTreatmentAvailableTimeslots: build.query<GetTreatmentAvailableTimeslotsApiResponse, GetTreatmentAvailableTimeslotsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/availableTimeslots/${queryArg.cartItemId}/${queryArg.productId}`,
          params: {
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            stationId: queryArg.stationId,
            customerIds: queryArg.customerIds,
            serviceIds: queryArg.serviceIds,
            preferenceGenders: queryArg.preferenceGenders,
            date: queryArg.date,
            calendarId: queryArg.calendarId,
            limit: queryArg.limit,
          },
        }),
        providesTags: ['getTreatmentAvailableTimeslots'],
      }),
      getAvailableTimeslotsByProduct: build.query<GetAvailableTimeslotsByProductApiResponse, GetAvailableTimeslotsByProductApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/availableTimeslotsByProduct',
          params: {
            date: queryArg.date,
            productIds: queryArg.productIds,
            cartItemId: queryArg.cartItemId,
            timeslotId: queryArg.timeslotId,
            customerIds: queryArg.customerIds,
            appointmentIds: queryArg.appointmentIds,
          },
        }),
        providesTags: ['getAvailableTimeslotsByProduct'],
      }),
      getOnlineCategories: build.query<GetOnlineCategoriesApiResponse, GetOnlineCategoriesApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/onlineCategories',
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ['getOnlineCategories'],
      }),
      getOnlineCategory: build.query<GetOnlineCategoryApiResponse, GetOnlineCategoryApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineCategory/${queryArg.id}`,
        }),
        providesTags: ['getOnlineCategory'],
      }),
      updateOnlineCategory: build.mutation<UpdateOnlineCategoryApiResponse, UpdateOnlineCategoryApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineCategory/${queryArg.id}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['updateOnlineCategory'],
      }),
      deleteOnlineCategory: build.mutation<DeleteOnlineCategoryApiResponse, DeleteOnlineCategoryApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineCategory/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['deleteOnlineCategory'],
      }),
      createOnlineCategory: build.mutation<CreateOnlineCategoryApiResponse, CreateOnlineCategoryApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/onlineCategory',
          method: 'POST',
          data: queryArg.createCategoryOnlineRequest,
        }),
        invalidatesTags: ['createOnlineCategory'],
      }),
      getOnlineProducts: build.query<GetOnlineProductsApiResponse, GetOnlineProductsApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/onlineProducts',
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ['getOnlineProducts'],
      }),
      getProductsByServiceGroup: build.query<GetProductsByServiceGroupApiResponse, GetProductsByServiceGroupApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/productsByServiceGroup',
          params: { therapistId: queryArg.therapistId },
        }),
        providesTags: ['getProductsByServiceGroup'],
      }),
      getOnlineProduct: build.query<GetOnlineProductApiResponse, GetOnlineProductApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineProduct/${queryArg.id}`,
        }),
        providesTags: ['getOnlineProduct'],
      }),
      updateOnlineProductDetail: build.mutation<UpdateOnlineProductDetailApiResponse, UpdateOnlineProductDetailApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineProduct/${queryArg.id}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['updateOnlineProductDetail'],
      }),
      deleteOnlineProduct: build.mutation<DeleteOnlineProductApiResponse, DeleteOnlineProductApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineProduct/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['deleteOnlineProduct'],
      }),
      getRelatedOnlineProducts: build.query<GetRelatedOnlineProductsApiResponse, GetRelatedOnlineProductsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/relatedOnlineProducts/${queryArg.id}`,
        }),
        providesTags: ['getRelatedOnlineProducts'],
      }),
      updateRelatedOnlineProducts: build.mutation<UpdateRelatedOnlineProductsApiResponse, UpdateRelatedOnlineProductsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/relatedOnlineProducts/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.relatedOnlinePutRequest,
        }),
        invalidatesTags: ['updateRelatedOnlineProducts'],
      }),
      getProductPackageList: build.query<GetProductPackageListApiResponse, GetProductPackageListApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/packageProducts/${queryArg.id}`,
        }),
        providesTags: ['getProductPackageList'],
      }),
      createOnlineProduct: build.mutation<CreateOnlineProductApiResponse, CreateOnlineProductApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/onlineProduct',
          method: 'POST',
          data: queryArg.createProductOnlineRequest,
        }),
        invalidatesTags: ['createOnlineProduct'],
      }),
      updateOnlineTags: build.mutation<UpdateOnlineTagsApiResponse, UpdateOnlineTagsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineTags/${queryArg.id}`,
          method: 'PUT',
          data: queryArg.onlineTagsPutRequest,
        }),
        invalidatesTags: ['updateOnlineTags'],
      }),
      getOnlineItemsBySlug: build.query<GetOnlineItemsBySlugApiResponse, GetOnlineItemsBySlugApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/onlineItems/${queryArg.slug}`,
          params: { sessionId: queryArg.sessionId },
        }),
        providesTags: ['getOnlineItemsBySlug'],
      }),
      treatmentOrderList: build.query<TreatmentOrderListApiResponse, TreatmentOrderListApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/treatmentOrderList',
          params: {
            pageName: queryArg.pageName,
            keyword: queryArg.keyword,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            dateBy: queryArg.dateBy,
            orderStatus: queryArg.orderStatus,
            paymentStatus: queryArg.paymentStatus,
            isGroupBooking: queryArg.isGroupBooking,
            isBanAndWatchList: queryArg.isBanAndWatchList,
          },
        }),
        providesTags: ['treatmentOrderList'],
      }),
      treatmentOrder: build.query<TreatmentOrderApiResponse, TreatmentOrderApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.idOrOrderNumber}`,
        }),
        providesTags: ['treatmentOrder'],
      }),
      confirmedTreatmentOrder: build.query<ConfirmedTreatmentOrderApiResponse, ConfirmedTreatmentOrderApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/confirmedTreatmentOrder/${queryArg.idOrOrderNumber}`,
        }),
        providesTags: ['confirmedTreatmentOrder'],
      }),
      cancelTreatmentOrder: build.mutation<CancelTreatmentOrderApiResponse, CancelTreatmentOrderApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.id}/cancel`,
          method: 'PATCH',
          params: {
            isCancellationNotificationRequired: queryArg.isCancellationNotificationRequired,
          },
        }),
        invalidatesTags: ['cancelTreatmentOrder'],
      }),
      patchPartialCancelTreatmentOrder: build.mutation<PatchPartialCancelTreatmentOrderApiResponse, PatchPartialCancelTreatmentOrderApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.orderId}/${queryArg.appointmentId}/cancel`,
          method: 'PATCH',
        }),
        invalidatesTags: ['patchPartialCancelTreatmentOrder'],
      }),
      postOrderCancellation: build.mutation<PostOrderCancellationApiResponse, PostOrderCancellationApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/order/${queryArg.id}/cancellation`,
          method: 'POST',
          data: queryArg.orderCancellationRequest,
        }),
        invalidatesTags: ['postOrderCancellation'],
      }),
      postOrderRefund: build.mutation<PostOrderRefundApiResponse, PostOrderRefundApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/order/${queryArg.id}/refund`,
          method: 'POST',
          data: queryArg.orderRefundRequest,
        }),
        invalidatesTags: ['postOrderRefund'],
      }),
      treatmentOrderResend: build.mutation<TreatmentOrderResendApiResponse, TreatmentOrderResendApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.id}/resend`,
          method: 'PATCH',
          data: queryArg.treatmentOrderResendRequest,
        }),
        invalidatesTags: ['treatmentOrderResend'],
      }),
      updateVault: build.mutation<UpdateVaultApiResponse, UpdateVaultApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.id}/updateVault`,
          method: 'PATCH',
          data: queryArg.updateVaultRequest,
        }),
        invalidatesTags: ['updateVault'],
      }),
      editProfile: build.mutation<EditProfileApiResponse, EditProfileApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.id}/editProfile`,
          method: 'PATCH',
          data: queryArg.editProfileRequest,
        }),
        invalidatesTags: ['editProfile'],
      }),
      emitAppointments: build.mutation<EmitAppointmentsApiResponse, EmitAppointmentsApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/appointments',
          method: 'POST',
          data: queryArg.emitAppointmentsRequest,
        }),
        invalidatesTags: ['emitAppointments'],
      }),
      getAppointments: build.query<GetAppointmentsApiResponse, GetAppointmentsApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/appointments',
          params: {
            pageName: queryArg.pageName,
            keyword: queryArg.keyword,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            dateBy: queryArg.dateBy,
            orderStatus: queryArg.orderStatus,
            date: queryArg.date,
            status: queryArg.status,
          },
        }),
        providesTags: ['getAppointments'],
      }),
      getStationsForReservationTreatment: build.query<GetStationsForReservationTreatmentApiResponse, GetStationsForReservationTreatmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/stations/${queryArg.url}`,
        }),
        providesTags: ['getStationsForReservationTreatment'],
      }),
      getStationsForTherapistSequence: build.query<GetStationsForTherapistSequenceApiResponse, GetStationsForTherapistSequenceApiArg>({
        query: () => ({ url: '/reservation/treatment/stations' }),
        providesTags: ['getStationsForTherapistSequence'],
      }),
      getPaymentLines: build.query<GetPaymentLinesApiResponse, GetPaymentLinesApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/paymentLines/${queryArg.orderId}`,
        }),
        providesTags: ['getPaymentLines'],
      }),
      getRelatedAppointmentsForStatusChange: build.query<GetRelatedAppointmentsForStatusChangeApiResponse, GetRelatedAppointmentsForStatusChangeApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointments/related/${queryArg.id}/status`,
        }),
        providesTags: ['getRelatedAppointmentsForStatusChange'],
      }),
      getRelatedAppointmentsForCancellation: build.query<GetRelatedAppointmentsForCancellationApiResponse, GetRelatedAppointmentsForCancellationApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointments/related/${queryArg.id}/cancellation`,
        }),
        providesTags: ['getRelatedAppointmentsForCancellation'],
      }),
      getAppointmentsForRefund: build.query<GetAppointmentsForRefundApiResponse, GetAppointmentsForRefundApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointments/${queryArg.id}/refund`,
        }),
        providesTags: ['getAppointmentsForRefund'],
      }),
      updateAppointmentsForStatus: build.mutation<UpdateAppointmentsForStatusApiResponse, UpdateAppointmentsForStatusApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointmentsForStatus/${queryArg.toBeStatus}`,
          method: 'POST',
          data: queryArg.body,
        }),
        invalidatesTags: ['updateAppointmentsForStatus'],
      }),
      getGroupAppointments: build.query<GetGroupAppointmentsApiResponse, GetGroupAppointmentsApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/groupAppointments',
          params: { date: queryArg.date },
        }),
        providesTags: ['getGroupAppointments'],
      }),
      addGroupAppointment: build.mutation<AddGroupAppointmentApiResponse, AddGroupAppointmentApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/groupAppointment',
          method: 'POST',
          data: queryArg.addGroupAppointmentRequest,
        }),
        invalidatesTags: ['addGroupAppointment'],
      }),
      removeGroupAppointment: build.mutation<RemoveGroupAppointmentApiResponse, RemoveGroupAppointmentApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/groupAppointment/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['removeGroupAppointment'],
      }),
      timeslotBatch: build.mutation<TimeslotBatchApiResponse, TimeslotBatchApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/timeslotBatch',
          method: 'POST',
          data: queryArg.createTimeslotBatchRequest,
        }),
        invalidatesTags: ['timeslotBatch'],
      }),
      checkOutAppointments: build.query<CheckOutAppointmentsApiResponse, CheckOutAppointmentsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/appointments/${queryArg.id}/${queryArg.appointmentIds}`,
        }),
        providesTags: ['checkOutAppointments'],
      }),
      cancelledAppointments: build.query<CancelledAppointmentsApiResponse, CancelledAppointmentsApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/cancelledAppointments',
          params: {
            pageName: queryArg.pageName,
            keyword: queryArg.keyword,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            dateBy: queryArg.dateBy,
            orderStatus: queryArg.orderStatus,
            date: queryArg.date,
            cancelledListType: queryArg.cancelledListType,
          },
        }),
        providesTags: ['cancelledAppointments'],
      }),
      voidCheckedOutOrder: build.mutation<VoidCheckedOutOrderApiResponse, VoidCheckedOutOrderApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/voidCheckedOutOrder',
          method: 'POST',
          data: queryArg.voidCheckedOutOrderRequest,
        }),
        invalidatesTags: ['voidCheckedOutOrder'],
      }),
      treatmentOrderConfirmationBatch: build.mutation<TreatmentOrderConfirmationBatchApiResponse, TreatmentOrderConfirmationBatchApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/treatmentOrderConfirmationBatch',
          method: 'POST',
          data: queryArg.treatmentOrderBatchRequest,
        }),
        invalidatesTags: ['treatmentOrderConfirmationBatch'],
      }),
      treatmentOrderSurveyBatch: build.mutation<TreatmentOrderSurveyBatchApiResponse, TreatmentOrderSurveyBatchApiArg>({
        query: queryArg => ({
          url: '/reservation/treatment/treatmentOrderSurveyBatch',
          method: 'POST',
          data: queryArg.treatmentOrderBatchRequest,
        }),
        invalidatesTags: ['treatmentOrderSurveyBatch'],
      }),
      confirmAppointments: build.mutation<ConfirmAppointmentsApiResponse, ConfirmAppointmentsApiArg>({
        query: queryArg => ({
          url: `/reservation/treatment/${queryArg.id}/confirmAppointments`,
          method: 'POST',
        }),
        invalidatesTags: ['confirmAppointments'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeReservationTreatmentApi };
export type PostAppointmentServiceApiResponse = /** status 200  */ VoidResponse;
export type PostAppointmentServiceApiArg = {
  productId: string;
  timeslotId: string;
  calendarId: string;
  stationId: string;
  appointmentRequest: AppointmentRequest;
};
export type PostAppointmentBlockApiResponse = /** status 200  */ VoidResponse;
export type PostAppointmentBlockApiArg = {
  endTimeslotId: string;
  startTimeslotId: string;
  calendarId: string;
  stationId: string;
  appointmentRequest: AppointmentRequest;
};
export type PostReservationApiResponse = /** status 200  */ TreatmentCartResponse;
export type PostReservationApiArg = {
  ttl?: number;
  override?: boolean;
  cartItemId?: any;
  productId: string;
  timeslotId: string;
  body: TreatmentAppointmentRequest[];
};
export type PatchReservationApiResponse = /** status 200  */ TreatmentCartResponse;
export type PatchReservationApiArg = {
  ttl?: number;
  override?: boolean;

  /** 변경 사항 즉시 적용 */
  isCompleted?: boolean;

  /** 예약 IDs */
  appointmentIds: any;

  /** 대표 서비스 */
  productId: string;

  /** 카트 or 주문 ID */
  cartItemId: string;
  patchRequest: PatchRequest;
};
export type PatchAppointmentServiceApiResponse = /** status 200  */ VoidResponse;
export type PatchAppointmentServiceApiArg = {
  /** 예약 IDs */
  appointmentIds: any;
  patchRequest: PatchRequest;
};
export type PatchAppointmentBlockApiResponse = /** status 200  */ VoidResponse;
export type PatchAppointmentBlockApiArg = {
  /** 예약 ID */
  id: string;
  patchRequest: PatchRequest;
};
export type GetCartForReservationTreatmentApiResponse = /** status 200  */ TreatmentCartResponse;
export type GetCartForReservationTreatmentApiArg = {
  appointmentIds?: string[];
  customerIds?: string[];
  isRebook?: boolean;
  sessionId?: string;
  cartItemId?: string;
};
export type RenewTimeslotForReservationTreatmentApiResponse = /** status 200  */ TreatmentCartResponse;
export type RenewTimeslotForReservationTreatmentApiArg = {
  ttl?: number;
  override?: boolean;
  id: string;
};
export type ReleaseTimeslotForReservationTreatmentApiResponse = /** status 200  */ TreatmentCartResponse;
export type ReleaseTimeslotForReservationTreatmentApiArg = {
  id: string;
};
export type PostTreatmentCartNumberOfGuestsApiResponse = /** status 200  */ TreatmentCartResponse;
export type PostTreatmentCartNumberOfGuestsApiArg = {
  numberOfGuests: any;
  body: GuestDto[];
};
export type AddGuestApiResponse = /** status 200  */ TreatmentCartResponse;
export type AddGuestApiArg = {
  cartItemId: string;
  guestDto: GuestDto;
};
export type UpdateTreatmentCartApiResponse = /** status 200  */ TreatmentCartResponse;
export type UpdateTreatmentCartApiArg = {
  cartItemId: string;
  patchRequest: PatchRequest;
};
export type DeleteCartForReservationTreatmentApiResponse = /** status 200  */ TreatmentCartResponse;
export type DeleteCartForReservationTreatmentApiArg = {
  appointmentIds?: string[];
  customerIds?: string[];
  isRebook?: boolean;
  override?: boolean;
  cartItemId: string;
};
export type PostTreatmentCartAddApiResponse = /** status 200  */ TreatmentCartResponse;
export type PostTreatmentCartAddApiArg = {
  ttl?: number;
  override?: boolean;
  timeslotId: string;
  productId: string;
  cartItemId: string;
  body: TreatmentCartWithCustomerId[];
};
export type CreateIncompleteOrderForReservationTreatmentApiResponse = /** status 200  */ IncompleteOrderResponse;
export type CreateIncompleteOrderForReservationTreatmentApiArg = {
  treatmentCreateIncompleteOrderRequest: TreatmentCreateIncompleteOrderRequest;
};
export type UpdateIncompleteOrderForReservationTreatmentApiResponse = /** status 200  */ IncompleteOrderResponse;
export type UpdateIncompleteOrderForReservationTreatmentApiArg = {
  id: string;
  treatmentUpdateIncompleteOrderRequest: TreatmentUpdateIncompleteOrderRequest;
};
export type ConfirmOrderForReservationTreatmentApiResponse = /** status 200  */ ConfirmOrderResponse;
export type ConfirmOrderForReservationTreatmentApiArg = {
  id: string;
  treatmentConfirmOrderRequest: TreatmentConfirmOrderRequest;
};
export type PlaceOrderForReservationTreatmentApiResponse = /** status 200  */ TreatmentOrderResponse;
export type PlaceOrderForReservationTreatmentApiArg = {
  placeOrderRequest: PlaceOrderRequest;
};
export type PatchPlaceOrderForReservationTreatmentApiResponse = /** status 200  */ TreatmentOrderResponse;
export type PatchPlaceOrderForReservationTreatmentApiArg = {
  cartItemId: string;
  patchPlaceOrderRequest: PatchPlaceOrderRequest;
};
export type GetStaffsApiResponse = /** status 200  */ StaffsResponse;
export type GetStaffsApiArg = {
  date?: string;
};
export type GetSchedulesByResourceApiResponse = /** status 200  */ SchedulesByResourceResponse;
export type GetSchedulesByResourceApiArg = {
  resourceType: ResourceType;
  date?: string;
  stationIds?: string[];
  therapistIds?: string[];
  dateFrom?: string;
  dateTo?: string;
  therapistId?: string;
  filterType?: ScheduleViewType;

  /** eg. espa/schedule or kbs/schedule */
  urlPath?: string;
};
export type GetAllSchedulesByResourceApiResponse = /** status 200  */ AllSchedulesByResourceResponse;
export type GetAllSchedulesByResourceApiArg = {
  resourceType: ResourceType;
  date?: string;
  stationIds?: string[];
  therapistIds?: string[];
  dateFrom?: string;
  dateTo?: string;
  therapistId?: string;
  filterType?: ScheduleViewType;

  /** eg. espa/schedule or kbs/schedule */
  urlPath?: string;
};
export type UpdateAppointmentApiResponse = /** status 200  */ VoidResponse;
export type UpdateAppointmentApiArg = {
  id: string;
  updateAppointmentPatchRequest: UpdateAppointmentPatchRequest;
};
export type GetAppointmentNotesApiResponse = /** status 200  */ AppointmentNotesResponse;
export type GetAppointmentNotesApiArg = {
  appointmentId: string;
};
export type CreateAppointmentNoteApiResponse = /** status 200  */ TreatmentCartResponse | AppointmentNotesResponse;
export type CreateAppointmentNoteApiArg = {
  noteRequest: NoteRequest;
};
export type UpdateAppointmentNoteApiResponse = /** status 200  */ TreatmentCartResponse | AppointmentNotesResponse;
export type UpdateAppointmentNoteApiArg = {
  /** noteId */
  id: string;
  noteRequest: NoteRequest;
};
export type DeleteAppointmentNoteApiResponse = /** status 200  */ TreatmentCartResponse;
export type DeleteAppointmentNoteApiArg = {
  appointmentId: string;
  message?: string;
  orderId?: string;

  /** use only when deleting notes in cart */
  cartItemId?: string;

  /** noteId */
  id: string;
};
export type GetAvailableDateslotsApiResponse = /** status 200  */ TreatmentAvailableTimeslotsResponse;
export type GetAvailableDateslotsApiArg = {
  dateFrom?: string;
  dateTo?: string;
  stationId?: string;
  customerIds: string[];
  serviceIds?: string[];
  preferenceGenders: PreferenceGender[];
  productId: string;
  cartItemId: string;
};
export type GetPrepareEditAppointmentApiResponse = /** status 200  */ PrepareEditAppointmentResponse;
export type GetPrepareEditAppointmentApiArg = {
  appointmentId: string;
};
export type GetPrepareNewAppointmentApiResponse = /** status 200  */ PrepareNewAppointmentResponse;
export type GetPrepareNewAppointmentApiArg = {
  serviceEndAt?: string;
  serviceStartAt?: string;
  therapistId?: string;
  calendarId: string;
  stationId: string;
  appointmentType: AppointmentType;
};
export type GetAvailableTherapistsApiResponse = /** status 200  */ AvailableTherapistResponse;
export type GetAvailableTherapistsApiArg = {
  excludeAppointmentIds?: string[];
  duration: number;
  timeslotId: string;
  productId: string;
  calendarId: string;
};
export type GetServiceTimeslotsApiResponse = /** status 200  */ TreatmentServiceTimeslotsResponse;
export type GetServiceTimeslotsApiArg = {
  stationIds: string[];
  calendarId: string;
};
export type GetTreatmentAvailableTimeslotsApiResponse = /** status 200  */ TreatmentAvailableTimeslotsResponse;
export type GetTreatmentAvailableTimeslotsApiArg = {
  dateFrom?: string;
  dateTo?: string;
  stationId?: string;
  customerIds: string[];
  serviceIds?: string[];
  preferenceGenders: PreferenceGender[];
  date?: string;
  calendarId?: string;
  limit?: number;
  productId: string;
  cartItemId: string;
};
export type GetAvailableTimeslotsByProductApiResponse = /** status 200  */ TreatmentAvailableTimeslotsResponse;
export type GetAvailableTimeslotsByProductApiArg = {
  date: string;
  productIds: string[];
  cartItemId?: string;
  timeslotId?: string;
  customerIds?: string[];
  appointmentIds?: string[];
};
export type GetOnlineCategoriesApiResponse = /** status 200  */ CategoriesOnlineResponse;
export type GetOnlineCategoriesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: SortBy;
  status?: Status;
};
export type GetOnlineCategoryApiResponse = /** status 200  */ CategoryOnlineResponse;
export type GetOnlineCategoryApiArg = {
  id: string;
};
export type UpdateOnlineCategoryApiResponse = /** status 200  */ CategoryOnlineResponse;
export type UpdateOnlineCategoryApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteOnlineCategoryApiResponse = /** status 200  */ VoidResponse;
export type DeleteOnlineCategoryApiArg = {
  id: string;
};
export type CreateOnlineCategoryApiResponse = /** status 200  */ CreateCategoryOnlineRequest;
export type CreateOnlineCategoryApiArg = {
  createCategoryOnlineRequest: CreateCategoryOnlineRequest;
};
export type GetOnlineProductsApiResponse = /** status 200  */ ProductsOnlineResponse;
export type GetOnlineProductsApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: SortBy;
  status?: Status;
};
export type GetProductsByServiceGroupApiResponse = /** status 200  */ ProductsByServiceGroupResponse;
export type GetProductsByServiceGroupApiArg = {
  therapistId?: string;
};
export type GetOnlineProductApiResponse = /** status 200  */ ProductOnlineResponse;
export type GetOnlineProductApiArg = {
  id: string;
};
export type UpdateOnlineProductDetailApiResponse = /** status 200  */ ProductOnlineResponse;
export type UpdateOnlineProductDetailApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteOnlineProductApiResponse = /** status 200  */ VoidResponse;
export type DeleteOnlineProductApiArg = {
  id: string;
};
export type GetRelatedOnlineProductsApiResponse = /** status 200  */ RelatedProductsResponse;
export type GetRelatedOnlineProductsApiArg = {
  /** productId or onlineProductId */
  id: string;
};
export type UpdateRelatedOnlineProductsApiResponse = /** status 200  */ RelatedProductsResponse;
export type UpdateRelatedOnlineProductsApiArg = {
  id: string;
  relatedOnlinePutRequest: RelatedOnlinePutRequest;
};
export type GetProductPackageListApiResponse = /** status 200  */ RelatedProductsResponse;
export type GetProductPackageListApiArg = {
  id: string;
};
export type CreateOnlineProductApiResponse = /** status 200  */ ProductOnlineResponse;
export type CreateOnlineProductApiArg = {
  createProductOnlineRequest: CreateProductOnlineRequest;
};
export type UpdateOnlineTagsApiResponse = /** status 200  */ UpdateOnlineTagsResponse;
export type UpdateOnlineTagsApiArg = {
  id: string;
  onlineTagsPutRequest: OnlineTagsPutRequest;
};
export type GetOnlineItemsBySlugApiResponse = /** status 200  */ OnlineItemsResponse;
export type GetOnlineItemsBySlugApiArg = {
  sessionId?: string;
  slug: string;
};
export type TreatmentOrderListApiResponse = /** status 200  */ TreatmentOrderListResponse;
export type TreatmentOrderListApiArg = {
  pageName?: string;
  keyword?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  pageSize?: number;
  dateBy?: number;
  orderStatus?: number;
  paymentStatus?: PaymentStatus;
  isGroupBooking: boolean;
  isBanAndWatchList: boolean;
};
export type TreatmentOrderApiResponse = /** status 200  */ TreatmentOrderResponse;
export type TreatmentOrderApiArg = {
  idOrOrderNumber: string;
};
export type ConfirmedTreatmentOrderApiResponse = /** status 200  */ TreatmentOrderResponse;
export type ConfirmedTreatmentOrderApiArg = {
  idOrOrderNumber: string;
};
export type CancelTreatmentOrderApiResponse = /** status 200  */ TreatmentOrderResponse;
export type CancelTreatmentOrderApiArg = {
  isCancellationNotificationRequired: boolean;

  /** Order Id */
  id: string;
};
export type PatchPartialCancelTreatmentOrderApiResponse = /** status 200  */ TreatmentOrderResponse;
export type PatchPartialCancelTreatmentOrderApiArg = {
  /** Appointment Id */
  appointmentId: string;

  /** Order Id */
  orderId: string;
};
export type PostOrderCancellationApiResponse = /** status 200  */ VoidResponse;
export type PostOrderCancellationApiArg = {
  id: string;
  orderCancellationRequest: OrderCancellationRequest;
};
export type PostOrderRefundApiResponse = /** status 200  */ VoidResponse;
export type PostOrderRefundApiArg = {
  id: string;
  orderRefundRequest: OrderRefundRequest;
};
export type TreatmentOrderResendApiResponse = unknown;
export type TreatmentOrderResendApiArg = {
  /** Order Id or Order Number */
  id: string;
  treatmentOrderResendRequest: TreatmentOrderResendRequest;
};
export type UpdateVaultApiResponse = /** status 200  */ UpdateVaultResponse;
export type UpdateVaultApiArg = {
  /** order Id */
  id: string;
  updateVaultRequest: UpdateVaultRequest;
};
export type EditProfileApiResponse = /** status 200  */ VoidResponse;
export type EditProfileApiArg = {
  /** order Id */
  id: string;
  editProfileRequest: EditProfileRequest;
};
export type EmitAppointmentsApiResponse = unknown;
export type EmitAppointmentsApiArg = {
  emitAppointmentsRequest: EmitAppointmentsRequest;
};
export type GetAppointmentsApiResponse = /** status 200  */ BuildingCheckInListResponse;
export type GetAppointmentsApiArg = {
  pageName?: string;
  keyword?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  pageSize?: number;
  dateBy?: number;
  orderStatus?: number;
  date?: string | null;
  status?: number;
};
export type GetStationsForReservationTreatmentApiResponse = /** status 200  */ StationResponse;
export type GetStationsForReservationTreatmentApiArg = {
  url: any;
};
export type GetStationsForTherapistSequenceApiResponse = /** status 200  */ StationForTherapistSequenceResponse;
export type GetStationsForTherapistSequenceApiArg = void;
export type GetPaymentLinesApiResponse = /** status 200  */ PaymentLinesResponse;
export type GetPaymentLinesApiArg = {
  orderId: string;
};
export type GetRelatedAppointmentsForStatusChangeApiResponse = /** status 200  */ AppointmentListResponse;
export type GetRelatedAppointmentsForStatusChangeApiArg = {
  /** appointment id, order Id */
  id: string;
};
export type GetRelatedAppointmentsForCancellationApiResponse = /** status 200  */ AppointmentCancellationListResponse;
export type GetRelatedAppointmentsForCancellationApiArg = {
  id: string;
};
export type GetAppointmentsForRefundApiResponse = /** status 200  */ AppointmentRefundListResponse;
export type GetAppointmentsForRefundApiArg = {
  id: string;
};
export type UpdateAppointmentsForStatusApiResponse = /** status 200  */ AppointmentListResponse;
export type UpdateAppointmentsForStatusApiArg = {
  toBeStatus: any;
  body: UpdateAppointmentsStatusRequest[];
};
export type GetGroupAppointmentsApiResponse = /** status 200  */ GroupAppointmentsResponse;
export type GetGroupAppointmentsApiArg = {
  date?: string;
};
export type AddGroupAppointmentApiResponse = /** status 200  */ AppointmentStatuesResponse;
export type AddGroupAppointmentApiArg = {
  addGroupAppointmentRequest: AddGroupAppointmentRequest;
};
export type RemoveGroupAppointmentApiResponse = /** status 200  */ AppointmentStatuesResponse;
export type RemoveGroupAppointmentApiArg = {
  groupKey: string;
  id: string;
};
export type TimeslotBatchApiResponse = /** status 200  */ VoidResponse;
export type TimeslotBatchApiArg = {
  createTimeslotBatchRequest: CreateTimeslotBatchRequest;
};
export type CheckOutAppointmentsApiResponse = /** status 200  */ CheckOutAppointmentsResponse;
export type CheckOutAppointmentsApiArg = {
  /** selected appointment IDs */
  appointmentIds?: any;

  /** order ID */
  id: string;
};
export type CancelledAppointmentsApiResponse = /** status 200  */ AppointmentsListResponse;
export type CancelledAppointmentsApiArg = {
  pageName?: string;
  keyword?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  pageSize?: number;
  dateBy?: number;
  orderStatus?: number;
  date?: string | null;
  cancelledListType: CancelledListType;
};
export type VoidCheckedOutOrderApiResponse = /** status 200  */ TreatmentOrderResponse;
export type VoidCheckedOutOrderApiArg = {
  voidCheckedOutOrderRequest: VoidCheckedOutOrderRequest;
};
export type TreatmentOrderConfirmationBatchApiResponse = /** status 200  */ VoidResponse;
export type TreatmentOrderConfirmationBatchApiArg = {
  treatmentOrderBatchRequest: TreatmentOrderBatchRequest;
};
export type TreatmentOrderSurveyBatchApiResponse = /** status 200  */ VoidResponse;
export type TreatmentOrderSurveyBatchApiArg = {
  treatmentOrderBatchRequest: TreatmentOrderBatchRequest;
};
export type ConfirmAppointmentsApiResponse = /** status 200  */ TreatmentOrderResponse;
export type ConfirmAppointmentsApiArg = {
  /** Order Id */
  id: string;
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type PreferenceGender = -1 | 0 | 1 | 2 | 3 | 4;
export type AppointmentMeta = {
  lockerNumber?: string;
  customerId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  phoneNumber?: string;
  admissionName?: string;
  checkInAt?: string;
  orderFromType?: number;
  isAnnounced?: number;
};
export type TreatmentAppointmentRequest = {
  customerId?: string | null;
  preferenceGender: PreferenceGender;

  /** 커플마사지와 같이 서비스 아이템이 별도로 존재하는 경우 사용함. */
  serviceId?: string;
  therapistId?: string;
  preferredTherapistId?: string | null;
  roomId?: string;
  lockerNumber?: string | null;
  meta?: AppointmentMeta | null;
  firstName: string;
  lastName: string;
  medicalNote?: string;
  serviceNote?: string;
};
export type RemindingType = 0 | 1 | 2 | 3;
export type CreateIncompleteOrderRequestContact = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isOptOutMarketing?: boolean;
  isOptOutReminding?: RemindingType;
};
export type ConfirmOrderRequestBillingAddress = {
  city: string;
  state: string;
  zipcode: string;
  address: string;
};
export type TenderType = 0 | 1 | 2 | 3 | 4 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18;
export type ConfirmOrderRequestPayment = {
  billingAddress?: ConfirmOrderRequestBillingAddress;
  tenderType: TenderType;
  deviceData?: string | null;

  /** 기존의 텐더를 사용할 때 PaymentLineId를 UniqueCode로 사용. */
  uniqueCode: string;
  isSkip?: boolean;
};
export type AppointmentRequest = {
  treatments?: TreatmentAppointmentRequest[] | null;
  contact?: CreateIncompleteOrderRequestContact;
  endTime?: string | null;
  payment?: ConfirmOrderRequestPayment | null;
  isOverrideBanWatchList?: boolean;
};
export type ProductOnlineMapCategory = {
  name: string;
  slug: string;
};
export type CategoryName = {
  name: string;
};
export type Categories = {
  onlineProductCategories?: ProductOnlineMapCategory[];
  productCategory?: CategoryName;
};
export type TreatmentGuestCartItem = {
  id: string;
  calendarId: string;
  customerId?: string;
  orderLineId?: string;
  appointmentId?: string;
  parentProductId?: string;
  parentAppointmentServiceName?: string;
  serviceName?: string;
  serviceTime?: string;
  guestName?: string;
  therapistId?: string;
  therapistName?: string;
  therapistGender?: string;
  preferenceGenderDescription?: string;
  preferenceGender?: PreferenceGender;
  productId?: string;
  imageUrl?: string;
  price?: number;
  taxRate?: number;
  tipRate?: number;
  treatmentDuration?: number;
  isForCouples?: boolean;
  isGenderMatched?: boolean;
  isPrepaidOnly?: boolean;
  createdAt?: string;
  serviceStartAt?: string;
  serviceEndAt?: string;
  serviceCheckInAt?: string;
  serviceCheckOutAt?: string;
  serviceDate?: string;
  notesCount?: number;
  isDeleted?: boolean;
  categories?: Categories;
  groupKey?: string | null;
  linkKey?: string | null;
  taxAmount?: number;
  tipAmount?: number;
};
export type Guest = {
  id?: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  isServiceSelected?: boolean;
  medicalNote?: string | null;
  serviceNote?: string | null;
  items?: TreatmentGuestCartItem[];
  lockerNumber?: string | null;
  meta?: AppointmentMeta | null;
};
export type PaymentLineDeletePaymentMethodFromVault = {
  clientMutationId?: string;
};
export type PaymentLineMetaResponseDataPaymentMethodDetails = {
  brandCode?: string;
  last4?: string;
  bin?: string;
  expirationMonth?: string;
  expirationYear?: string;
  cardholderName?: string;
  uniqueNumberIdentifier?: string;
  cardOnFileNetworkTokenized?: string;
};
export type PaymentLineMetaResponseDataPayment = {
  id?: string;
  usage?: string;
  details?: PaymentLineMetaResponseDataPaymentMethodDetails;
};
export type PaymentLineMetaResponseDataPaymentMethodVerificationRiskData = {
  id?: string;
  decision?: string;
  decisionReasons?: string[];
};
export type PaymentLineMetaResponseDataPaymentMethodVerificationProcessorResponse = {
  legacyCode?: string;
  message?: string;
};
export type PaymentLineMetaResponseDataPaymentMethodVerification = {
  status?: string;
  riskData?: PaymentLineMetaResponseDataPaymentMethodVerificationRiskData;
  processorResponse?: PaymentLineMetaResponseDataPaymentMethodVerificationProcessorResponse;
};
export type PaymentLineMetaResponseDataVaultPaymentMethod = {
  paymentMethod?: PaymentLineMetaResponseDataPayment;
  verification?: PaymentLineMetaResponseDataPaymentMethodVerification;
};
export type PaymentLineMetaResponseVerificationPaymentMethod = {
  id?: string;
};
export type PaymentLineMetaResponseVerificationProcessorResponse = {
  legacyCode?: string;
  message?: string;
};
export type PaymentLineMetaResponseVerification = {
  status?: string;
  merchantAccountId?: string;
  gatewayRejectionReason?: string;
  paymentMethod?: PaymentLineMetaResponseVerificationPaymentMethod;
  processorResponse?: PaymentLineMetaResponseVerificationProcessorResponse;
};
export type PaymentLineMetaResponseDataVerifyPaymentMethod = {
  verification?: PaymentLineMetaResponseVerification;
};
export type PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData = {
  id?: string;
  decision?: string;
  decisionReasons?: string[];
};
export type PaymentLineMetaResponseDataChargePaymentMethodTransaction = {
  status?: string;
  riskData?: PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData;
};
export type PaymentLineMetaResponseDataChargePaymentMethod = {
  transaction?: PaymentLineMetaResponseDataChargePaymentMethodTransaction;
};
export type PaymentLineMetaResponseDataReverseTransactionReversal = {
  id?: string;
  status?: string;
};
export type PaymentLineMetaResponseDataReverseTransaction = {
  reversal?: PaymentLineMetaResponseDataReverseTransactionReversal;
};
export type PaymentLineMetaResponseData = {
  deletePaymentMethodFromVault?: PaymentLineDeletePaymentMethodFromVault;
  vaultPaymentMethod?: PaymentLineMetaResponseDataVaultPaymentMethod;
  tokenizeCreditCard?: string | null;
  verifyPaymentMethod?: PaymentLineMetaResponseDataVerifyPaymentMethod;
  chargePaymentMethod?: PaymentLineMetaResponseDataChargePaymentMethod;
  reverseTransaction?: PaymentLineMetaResponseDataReverseTransaction;
};
export type PaymentLineMetaResponse = {
  data?: PaymentLineMetaResponseData;
};
export type PaymentLineMeta = {
  response?: PaymentLineMetaResponse;
};
export type TreatmentPaymentLines = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  type: number;
  tenderType: number;
  label?: string;
  grossAmount: number;
  adjustmentAmount: number;
  tipAmount: number;
  taxAmount: number;
  requestAmount: number;
  uniqueCode?: string;
  approvedAmount: number;
  customerId?: string;
  reason?: string;
  relationId?: string;
  status: number;
  cardHolderName?: string;
  billingAddress?: string;
  paymentLineId?: string;
  description?: string;
  meta?: PaymentLineMeta[];
  customerName?: string;
  paymentCompletedAt?: string;
  clientMutationId?: string;
};
export type GroupCart = {
  customer?: CreateIncompleteOrderRequestContact;
  numberOfGuests?: number | string;
  numberOfItems?: number | string;
  guests?: Guest[];
  availableTimeSlot?: string;
  isReadyToCheckout?: boolean;
  isReadyToPlaceOrder?: boolean;
  totalAmount?: number;
  subtotalAmount?: number;
  taxAmount?: number;
  tipAmount?: number;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  isAgreed?: boolean;
  isMedicalNoteRequired?: boolean;
  expireIn?: number;
  expireAt?: string;
  isRenewable?: boolean;
  isRenewed?: boolean | null;
  serviceDate?: string;
  serviceDateUtc?: string;
  calendarId?: string;
  payment?: TreatmentPaymentLines | null;
  action?: string | null;
};
export type OrderType = 0 | 1 | 2 | 3 | 99;
export type DeliveryMethod = 1 | 2 | 3;
export type CreateIncompleteOrderRequestDeliveryShippingAddress = {
  city: string;
  state: string;
  zipcode: string;
  address: string;
};
export type CreateIncompleteOrderRequestDeliveryPickup = {
  fullName: string;
  phone: string;
  dateTime: string;
};
export type CreateIncompleteOrderRequestDelivery = {
  deliveryMethod: DeliveryMethod;
  emailTo: string;
  shippingAddress?: CreateIncompleteOrderRequestDeliveryShippingAddress;
  pickup?: CreateIncompleteOrderRequestDeliveryPickup;
  nameFrom: string;
  nameTo: string;
  shippingFullName?: string;
  personalMessage?: string;
};
export type PlaceOrderRequest = {
  payment: ConfirmOrderRequestPayment;
  orderType?: OrderType;
  promotionCode?: string;
  customer?: CreateIncompleteOrderRequestContact;
  delivery?: CreateIncompleteOrderRequestDelivery;
  cartItemIds: string[];
  isIncludeDetails?: boolean;
  isOverrideBanWatchList?: boolean;
};
export type TreatmentCartItem = {
  id: string;
  productId?: string;
  orderId?: string;
  orderNumber?: string;
  quantity?: number;
  meta?: GroupCart;
  payload?: PlaceOrderRequest;
};
export type TreatmentCart = {
  id: string;
  items: TreatmentCartItem[];
};
export type TreatmentCartResponse = {
  code?: number;
  messages?: string[];
  result?: TreatmentCart;
};
export type PatchOperation = 'add' | 'replace' | 'test' | 'remove' | 'move' | 'copy';
export type Patch = {
  op: PatchOperation;

  /** Path */
  path: string;

  /** Value */
  value?: object;

  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type GuestDto = {
  firstName: string;
  lastName: string;
  customerId?: string;
  medicalNote?: string;
  serviceNote?: string;
  lockerNumber?: string | null;
  meta?: AppointmentMeta | null;
};
export type TreatmentCartWithCustomerId = {
  customerId?: string | null;
  preferenceGender: PreferenceGender;

  /** 커플마사지와 같이 서비스 아이템이 별도로 존재하는 경우 사용함. */
  serviceId?: string;
  therapistId?: string;
  preferredTherapistId?: string | null;
  roomId?: string;
  lockerNumber?: string | null;
  meta?: AppointmentMeta | null;
};
export type ProfileDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  fullName: string;
};
export type CustomerDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  isGuest: boolean;
  phoneNumber1?: string;
  phoneNumber2?: string;
  phoneNumber3?: string;
  membershipNumber?: string;
  membershipType?: number;
  membershipValidFrom?: string;
  membershipValidUntil?: string;
  isOptOutMarketing: boolean;
  isOptOutReminding?: number;
  restrictionType?: number;
  vaultCode?: string;
  legacyId?: string;
  status: number;
  Profile?: ProfileDto;
};
export type Rates = {
  id?: string;
  name?: string;
  taxCode?: string;
  rate: number;
  type: number;
  tipAmount?: number;
  discountAmount?: number;
};
export type OrderLineGiftCertificateMeta = {
  id: string;
  name: string;
  discountAmount: number;
  tipAmount: number;
  isCommission: boolean;
  capacity: number;
};
export type OrderLineMeta = {
  rates?: Rates[];
  giftCertificate?: OrderLineGiftCertificateMeta;
  orderType?: OrderType;
};
export type CustomerAddresses = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  customerId: string;
  type: number;
  recipientName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  status: number;
};
export type OrderShippingDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  type: number;
  addressId?: string;
  trackingNumber?: string;
  shippingDate?: string;
  senderName?: string;
  senderEmail?: string;
  recipientName?: string;
  recipientEmail?: string;
  pickupFullName?: string;
  pickupDateTime?: string;
  pickupPhone?: string;
  isPickupAgreeTerms?: boolean;
  personalMessage?: string;
  requestMessage?: string;
  status: number;
  CustomerAddress?: CustomerAddresses;
};
export type ProductMeta = {
  templateName?: string;
  expiration?: number;
  followListingPrice?: boolean;
  onlinePriceMethod?: number;
};
export type Product = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
};
export type Ticket = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  reservationId: string;
  orderLineId?: string;
  status: number;
  refs?: string;
};
export type OrderLinesDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  sequence?: number;
  categoryId: string;
  productId: string;
  customerId?: string;
  orderShippingId?: string;
  appointmentId?: string;
  salesRepresentativeId?: string;
  listPrice: number;
  sellingPrice: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  promotionAmount: number;
  totalAmount: number;
  refundAmount: number;
  status: number;
  orderLineId?: string;
  description?: string;
  meta?: OrderLineMeta;
  productType: number;
  productName?: string;
  endOfDateId?: string;
  OrderShipping?: OrderShippingDto;
  Product?: Product;
  Ticket?: Ticket;
};
export type Timeslot = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  dateslotId: string;
  time: string;
  capacity: number;
  status: number;
};
export type Reservation = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  productId: string;
  dateslotId: string;
  timeslotId: string;
  numberOfGuest: number;
  orderId?: string;
  status: number;
  refs?: string;
  Timeslot?: Timeslot;
};
export type PaymentLines = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  type: number;
  tenderType: number;
  label?: string;
  grossAmount: number;
  adjustmentAmount: number;
  tipAmount: number;
  taxAmount: number;
  requestAmount: number;
  uniqueCode?: string;
  approvedAmount: number;
  customerId?: string;
  reason?: string;
  relationId?: string;
  status: number;
  cardHolderName?: string;
  billingAddress?: string;
  paymentLineId?: string;
  description?: string;
  meta?: PaymentLineMeta;
  customerName?: string;
};
export type Channel = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderNumber: string;
  paymentNumber?: string;
  legacyNumber?: string;
};
export type IncompleteOrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type IncompleteOrderResponse = {
  code?: number;
  messages?: string[];
  result?: IncompleteOrderDto;
};
export type TreatmentCreateIncompleteOrderRequest = {
  customer?: CreateIncompleteOrderRequestContact;
  delivery?: CreateIncompleteOrderRequestDelivery;
  orderType?: OrderType;
  cartItemIds?: string[];
  cartItem?: TreatmentCartItem;
};
export type TreatmentUpdateIncompleteOrderRequest = {
  customer: CreateIncompleteOrderRequestContact;
  delivery: CreateIncompleteOrderRequestDelivery;
  orderType?: OrderType;
  cartItem?: TreatmentCartItem;
};
export type ConfirmOrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type ConfirmOrderResponse = {
  code?: number;
  messages?: string[];
  result?: ConfirmOrderDto;
};
export type TreatmentConfirmOrderRequest = {
  payment: ConfirmOrderRequestPayment;
  orderType?: OrderType;
  promotionCode?: string;
  cartItem?: TreatmentCartItem;
};
export type OrderStatus = 0 | 1 | 2 | 9 | 11 | 21 | 22 | 23 | 24 | 31;
export type PaymentStatus = 0 | 1 | 2 | 3 | 4 | 6 | 11 | 12 | 13 | 21 | 22 | 23 | 9;
export type TreatmentProductOnline = {
  imageUrl?: string;
};
export type TreatmentProductDto = {
  id: string;
  gtin?: string;
  isPrepaidOnly?: boolean;
  ProductOnline?: TreatmentProductOnline;
};
export type TreatmentOrderLineAppointment = {
  id?: string;
  calendarId?: string;
  customerId?: string;
  serviceTime?: string;
  serviceStartAt?: string;
  serviceEndAt?: string;
  serviceDate?: string;
  isForCouples?: boolean;
  therapistGender?: string;
  appointmentType?: number;
  imageUrl?: string;
  status?: number;
  appointmentId?: string;
  serviceId?: string;
  parentServiceId?: string;
  serviceName?: string;
  parentAppointmentServiceName?: string;
  customerName?: string;
  firstName?: string;
  lastName?: string;
  therapistId?: string;
  therapistName?: string;
  preferenceGenderDescription?: string;
  preferenceGender?: PreferenceGender;
  groupKey?: string;
  meta?: AppointmentMeta | null;
  refKey?: string;
  roomName?: string;
  contact1Id?: string;
  contact2Id?: string;
  contact1Name?: string;
  contact1Phone?: string;
  contact1Email?: string;
  contact2Name?: string;
  contact2Phone?: string;
  contact2Email?: string;
  serviceCheckInAt?: string | null;
  serviceCheckOutAt?: string | null;
  createdAt?: string;
  isServiceCancellationAvailable?: boolean;
  linkKey?: string;
};
export type NoteDto = {
  id?: string;
  relationId?: string;
  message?: string;
  level?: number;
  createdAt?: string;
  createdByName?: string;
};
export type PaymentStatusDisplay = {
  feeAmount: number;
  reason: string;
  paymentStatus: PaymentStatus;
  tenderType: TenderType;
};
export type TreatmentOrderLinesDto = {
  id?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId?: string;
  sequence?: number;
  categoryId?: string;
  productId?: string;
  customerId?: string;
  orderShippingId?: string;
  appointmentId?: string;
  salesRepresentativeId?: string;
  listPrice?: number;
  sellingPrice?: number;
  tax1Amount?: number;
  tax2Amount?: number;
  extraChargeAmount?: number;
  tip1Amount?: number;
  tip2Amount?: number;
  discount1Amount?: number;
  discount2Amount?: number;
  discount3Amount?: number;
  promotionAmount?: number;
  totalAmount?: number;
  refundAmount?: number;
  status?: number;
  orderLineId?: string;
  description?: string;
  meta?: OrderLineMeta;
  productType?: number;
  productName?: string;
  endOfDateId?: string;
  Product?: TreatmentProductDto;
  Appointment?: TreatmentOrderLineAppointment;
  Notes?: NoteDto[];
  notesCount?: number;
  isSettled?: boolean;
  orderLinePaymentStatus?: PaymentStatusDisplay[] | null;
};
export type RelatedOrder = {
  orderNumber?: string;
  path?: string;
};
export type TreatmentOrderDetailDto = {
  id?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber?: string;
  status?: number;
  deviceId?: string;
  cashierId?: string;
  cashierName?: string;
  customerId?: string;
  receiptEmail?: string;
  amount?: number;
  tax1Amount?: number;
  tax2Amount?: number;
  extraChargeAmount?: number;
  tip1Amount?: number;
  tip2Amount?: number;
  discount1Amount?: number;
  discount2Amount?: number;
  discount3Amount?: number;
  shippingAmount?: number;
  totalAmount?: number;
  paidAmount?: number;
  refundAmount?: number;
  refundShippingAmount?: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName?: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  reservationStatus?: OrderStatus;
  paymentStatus?: PaymentStatus;
  groupName?: string;
  serviceDate?: string;
  serviceDateUtc?: string;
  isCancellationAvailable?: boolean;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  totalDueAmount?: number;
  numberOfGuests?: number | string;
  numberOfServices?: number | string;
  orderDate?: string;
  isServiceCheckoutAvailable?: boolean;
  isVoidAvailable?: boolean;
  Customer?: CustomerDto;
  OrderLines?: TreatmentOrderLinesDto[];
  PaymentLines?: TreatmentPaymentLines[];
  isRefundAvailable?: boolean;
  isConfirmationAvailable?: boolean;
  isConfirmed?: boolean;
  isSettled?: boolean;
  relatedOrders?: RelatedOrder[];
};
export type TreatmentOrderResponse = {
  code?: number;
  messages?: string[];
  result?: TreatmentOrderDetailDto;
};
export type PatchPlaceOrderRequest = {
  jsonPatch: Patch[];
  isUpdateNotificationRequired?: boolean;
  isOverrideBanWatchList?: boolean;
};
export type RecordType = 1 | 2 | 4 | 8 | 16;
export type StaffDto = {
  id: string;
  name: string;
  gender?: string;
  serviceGroup?: string;
  stationId?: string;
  roleId?: string;
  fullName?: string;
  roleSequence?: number;
  rank?: number;
  type: RecordType;
  hasWorkingSchedule?: boolean;
  children?: StaffDto[];
  parentId?: string;
};
export type StaffsResponse = {
  code?: number;
  messages?: string[];
  result?: StaffDto[];
};
export type ResourceType = 1 | 2;
export type Calendar = {
  id: string;
  date: string;
  dateUtc: string;
};
export type Therapist = {
  id: string;
  name: string;
  gender?: string;
  serviceGroup?: string;
  stationId?: string;
  roleId?: string;
  fullName?: string;
  roleSequence?: number;
  rank?: number;
};
export type WorkingSchedule = {
  workStartUtc?: string;
  workEndUtc?: string;
};
export type Resource = {
  id: string;
  name: string;
  gender?: string;
  serviceGroup?: string;
  stationId?: string;
  roleId?: string;
  fullName?: string;
  roleSequence?: number;
  rank?: number;

  /** ResourceType: 1인 경우에만 반환 */
  workingSchedule?: WorkingSchedule;

  /** ResourceType: 2인 경우에만 반환 */
  therapist?: Therapist;
  isToday: boolean;
};
export type BookingStatus = -1 | 0 | 1 | 11 | 12 | 13 | 20 | 21 | 30 | 31 | 40 | 50 | 60;
export type BookingStatues = {
  confirmed?: boolean;
  buildingCheckedIn?: boolean;
  serviceCheckedIn?: boolean;
  serviceCheckedOut?: boolean;
};
export type Icons = {
  isGenderNotMatched?: boolean;
  isConfirmedByUser?: boolean;
  isConfirmedByGuest?: boolean;
  isSameDayBooking?: boolean;
  isInHouseBooking?: boolean;
  isCouples?: boolean;
  isGrouped?: boolean;
  isLinked?: boolean;
  isPaid?: boolean;
  isPartiallyPaid?: boolean;
  isRefunded?: boolean;
  isPaidWithGiftCard?: boolean;
  isAdjustmentApplied?: boolean;
  isAnnounced?: boolean;
};
export type LinkedAppointment = {
  id: string;
  calendarId: string;
  serviceName?: string;
  therapistName?: string;
  therapistGender?: string;
  serviceTime?: string;
};
export type Appointment = {
  id: string;
  calendarId: string;
  serviceId?: string;
  customerId?: string;
  contact1Id?: string;
  therapistId?: string;
  roomId?: string;
  appointmentType: number;
  balancePaidOffAt?: string;
  groupKey?: string;
  linkKey?: string;
  refKey?: string;
  status: number;
  appointmentId?: string;
  serviceName?: string;
  customerName?: string;
  therapistName?: string;
  therapistGender?: string;
  roomName?: string;
  contact1Name?: string;
  contact1Phone?: string;
  contact1Email?: string;
  startUtc: string;
  endUtc: string;
  serviceTime?: string;
  serviceDate?: string;
  customerEmail?: string;
  customerPhoneNumber?: string;
  preferenceGender: PreferenceGender;
  bookingStatus?: BookingStatus;
  bookingStatues?: BookingStatues;
  icons?: Icons;
  notes?: NoteDto[];
  title?: string;
  note?: string;
  timestamp?: number;
  orderId?: string | null;
  isOptOutReminding?: RemindingType;
  cleaningTime?: string | null;
  orderLineId?: string | null;
  isServiceCancellationAvailable?: boolean;
  linkedAppointments?: LinkedAppointment[];
  orderNumber?: string | null;
  isSettled?: boolean;
};
export type ScheduleViewType = 0 | 1 | 2;
export type SchedulesByResourceDto = {
  resourceType: ResourceType;

  /** ResourceType: 1인 경우에만 반환 */
  calendar?: Calendar;

  /** ResourceType: 2인 경우에만 반환 */
  therapist?: Therapist;
  resources?: Resource[];
  appointments: Appointment[];
  filterType?: ScheduleViewType;
};
export type SchedulesByResourceResponse = {
  code?: number;
  messages?: string[];
  result?: SchedulesByResourceDto;
};
export type StationScheduleResourceForDay = {
  stationId: string;
  businessStart: string;
  businessEnd: string;
};
export type StationScheduleResourceForDayDto = {
  minimumBusinessHourStart: string;
  maximumBusinessHourEnd: string;
  beforeBusinessHourStart: string;
  afterBusinessHourEnd: string;
  businessSchedule: StationScheduleResourceForDay[];
};
export type AllSchedulesByResourceDto = {
  resourceType: ResourceType;

  /** ResourceType: 1인 경우에만 반환 */
  calendar?: Calendar;

  /** ResourceType: 2인 경우에만 반환 */
  therapist?: Therapist;
  resources?: Resource[];
  appointments: Appointment[];
  filterType?: ScheduleViewType;
  StationSchedules?: StationScheduleResourceForDayDto;
};
export type AllSchedulesByResourceResponse = {
  code?: number;
  messages?: string[];
  result?: AllSchedulesByResourceDto;
};
export type PartialTypeClass = {
  id?: string;
  calendarId?: string;
  serviceId?: string;
  customerId?: string;
  contact1Id?: string;
  therapistId?: string;
  roomId?: string;
  appointmentType?: number;
  balancePaidOffAt?: string;
  groupKey?: string;
  linkKey?: string;
  refKey?: string;
  status?: number;
  appointmentId?: string;
  serviceName?: string;
  customerName?: string;
  therapistName?: string;
  therapistGender?: string;
  roomName?: string;
  contact1Name?: string;
  contact1Phone?: string;
  contact1Email?: string;
  startUtc?: string;
  endUtc?: string;
  serviceTime?: string;
  serviceDate?: string;
  customerEmail?: string;
  customerPhoneNumber?: string;
  preferenceGender?: PreferenceGender;
  bookingStatus?: BookingStatus;
  bookingStatues?: BookingStatues;
  icons?: Icons;
  notes?: NoteDto[];
  title?: string;
  note?: string;
  timestamp?: number;
  orderId?: string | null;
  isOptOutReminding?: RemindingType;
  cleaningTime?: string | null;
  orderLineId?: string | null;
  isServiceCancellationAvailable?: boolean;
  linkedAppointments?: LinkedAppointment[];
  orderNumber?: string | null;
  isSettled?: boolean;
};
export type UpdateAppointmentPatchRequest = {
  jsonPatch: Patch[];
  isUpdateNotificationRequired?: boolean;
  currentAppointment?: PartialTypeClass;
};
export type AppointmentNotesResponse = {
  code?: number;
  messages?: string[];
  result?: NoteDto[];
};
export type NoteRequest = {
  relationId?: string;

  /** Guest 1 or Employee 2 (default is Employee 2) */
  level?: 1 | 2;
  message: string;

  /** only when creating & editing notes in cart */
  cartItemId?: string;

  /** customerId */
  customerId?: string;
  addNoteToAll?: boolean;
  orderId?: string;

  /** customer name */
  customerName?: string;
};
export type AvailableServiceTimeslotsByTherapist = {
  therapistId: string;
  therapistName: string;
  roleId: string;
  gender: string;
  rank: number;
  products?: string[];
};
export type AvailableServiceProperties = {
  totalTherapists: number;
  totalTherapistByMale: number;
  totalTherapistByFemale: number;
  availableTherapist: number;
  availableTherapistByMale: number;
  availableTherapistByFemale: number;
  groupsBooked: number;
  singlesBooked: number;
  singleRoom: number;
  groupRoom: number;
  totalRoom: number;
  availableRoom: number;
  minAvailability: number;
  maxAvailability: number;
  isSoldOut: boolean;
  availability: number;
};
export type AvailableServiceTimeslotsByProduct = {
  productId: string;
  productName: string;
  productServiceType: number;
  productPrice: number;
  properties?: AvailableServiceProperties;
  therapists?: AvailableServiceTimeslotsByTherapist[];
};
export type AvailableServiceTimeslots = {
  id: string;
  time: string;
  isClosed: boolean;
  isPast: boolean;
  isSoldOut: boolean;
  isBusy: boolean;
  availability: string;
  isLimitedAvailability: boolean;
  therapists?: AvailableServiceTimeslotsByTherapist[];
  products?: AvailableServiceTimeslotsByProduct[];
  properties?: AvailableServiceProperties;
};
export type AvailableServiceStations = {
  serviceGroup?: string;
  stationId?: string;
  roleId?: string;
  timeslots?: AvailableServiceTimeslots[];
};
export type AvailableServiceDto = {
  calendarId: string;
  dateUtc: string;
  isPast?: boolean;
  isClosed?: boolean;
  year?: number;
  month?: number;
  monthName?: string;
  dayOfMonth?: number;
  dayOfWeek?: number;
  dayOfWeekName?: string;
  timeslots?: AvailableServiceTimeslots[];
  stations?: AvailableServiceStations[];
  properties?: AvailableServiceProperties;
};
export type TreatmentAvailableTimeslotsResponse = {
  code?: number;
  messages?: string[];
  result?: AvailableServiceDto[];
};
export type AvailableTherapist = {
  id: string;
  fullName: string;
  gender: string;
};
export type ProductOnlinePreferenceGender = {
  value?: number;
  description?: string;
};
export type PreferenceGenderByShopAndBackoffice = {
  /** [
      { value: 2, description: 'Female' },
        { value: 1, description: 'Male' },
        { value: 0, description: 'Any' }
    ] */
  shop: ProductOnlinePreferenceGender[];

  /** [
      { value: 2, description: 'Female' },
        { value: 1, description: 'Male' },
        { value: 0, description: 'Any' }
    ] */
  backoffice: ProductOnlinePreferenceGender[];
};
export type ProductOnlineMeta = {
  genderShouldMatch?: boolean;
  duration?: number;
  minimumGuest?: number;
  maximumGuest?: number;
  preferenceGenders: PreferenceGenderByShopAndBackoffice;
  isForCouples?: boolean;

  /** for couple products sort order sortOrder: ["PD13QRWJZI0RYU","PD13Q2OZXK41AI","PD13QCWNKFWC9Y","PD13QA99FR3CVI"] */
  sortOrder?: string[];
};
export type ProductsByServiceGroupDto = {
  id?: string;
  name?: string;
  type?: number;
  duration?: number;
  price?: number;
  meta: ProductOnlineMeta;
  parentId?: string;
  children?: ProductsByServiceGroupDto[];
};
export type ServiceGroupDto = {
  stationId?: string;
  roleId?: string;
  name?: string;
  products?: ProductsByServiceGroupDto[];
};
export type AppointmentType = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type PrepareEditAppointmentDtoAppointment = {
  id: string;
  stationId: string;
  roomId?: string | null;
  roomName?: string | null;
  therapistId: string;
  therapistName: string;
  therapistGender: string;
  preferenceGender: PreferenceGender;
  appointmentType: AppointmentType;
  appointmentId: string | null;
  description: string | null;
  serviceId: string | null;
  parentServiceId: string | null;
  serviceName: string | null;
  serviceStartAt: string;
  serviceEndAt: string;
  serviceCheckInAt: string;
  calendarId: string;
  customerId: string | null;
};
export type PrepareEditAppointmentDtoGuest = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  lockerNumber: string;
  meta: AppointmentMeta;
  medicalNote: string | null;
  isOptOutReminding: RemindingType;
};
export type PrepareEditAppointmentDto = {
  date: string;
  isToday: boolean;
  isPast: boolean;
  isFuture: boolean;
  timeslots: Timeslot[];
  therapist: AvailableTherapist;
  timeslotId: string | null;
  startTimeslotId: string | null;
  endTimeslotId: string | null;
  serviceGroups: ServiceGroupDto[];
  appointments: PrepareEditAppointmentDtoAppointment[];
  guests: PrepareEditAppointmentDtoGuest[];
};
export type PrepareEditAppointmentResponse = {
  code?: number;
  messages?: string[];
  result: PrepareEditAppointmentDto;
};
export type PrepareNewAppointmentDto = {
  date: string;
  isToday: boolean;
  isPast: boolean;
  isFuture: boolean;
  timeslots: Timeslot[];
  therapist: AvailableTherapist;
};
export type PrepareNewAppointmentResponse = {
  code?: number;
  messages?: string[];
  result: PrepareNewAppointmentDto;
};
export type AvailableTherapistResponse = {
  code?: number;
  messages?: string[];
  result: AvailableTherapist[];
};
export type ServiceTimeslotRooms = {
  name: string;
  totalRooms: number;
  roomIds: string[];
};
export type ServiceTimeslot = {
  totalGroupRooms: number;
  totalSingleRooms: number;
  time: string;
  rooms?: ServiceTimeslotRooms[];
};
export type ServiceTimeslotDto = {
  stationId: string;
  timeslots?: ServiceTimeslot[];
};
export type TreatmentServiceTimeslotsResponse = {
  code?: number;
  messages?: string[];
  result?: ServiceTimeslotDto[];
};
export type OnlineTag = {
  tag?: string;
  sequence?: number;
};
export type CategoryOnline = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  slug: string;
  sequence: number;
  visibility: number;
  imageUrl?: string;
  parentId?: string;
  meta?: object;
  headerHtml?: string;
  publishedAt: string;
  status: number;
  categoryId?: string;
  OnlineTags?: OnlineTag[];
};
export type CategoriesOnlineResponse = {
  code?: number;
  messages?: string[];
  result?: CategoryOnline[];
};
export type SortBy = 'name' | 'fullName' | 'updatedAt' | 'path' | 'sequence';
export type Status = 0 | 1 | 2;
export type CategoryOnlineResponse = {
  code?: number;
  messages?: string[];
  result?: CategoryOnline;
};
export type CreateCategoryOnlineRequest = {
  name: string;
  slug: string;
  sequence: number;
  visibility: number;
  imageUrl?: string;
  parentId?: string;
  meta?: object;
  categoryId?: string;
};
export type ProductOnlineMapSequence = {
  sequence: number;
};
export type ProductOnlineDto = {
  id: string;
  productId: string;
  slug: string;
  name: string;
  nameHtml: string;
  summaryHtml?: string;
  textHtml: string;
  extraTextHtml?: string;
  headerHtml?: string;
  imageUrl?: string;
  price: number;
  meta?: ProductOnlineMeta;
  bookStatus?: boolean;
  OnlineTags?: OnlineTag[];
  ProductOnlineMap?: ProductOnlineMapSequence[];
  categories?: Categories;
};
export type ProductOnlineMapSequenceWithCategory = {
  sequence: number;
  CategoryOnline?: ProductOnlineMapCategory;
};
export type ProductRecursiveCategory = {
  RecursiveCategory?: CategoryName;
};
export type ProductOnline = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  productId: string;
  slug: string;
  name: string;
  nameHtml: string;
  summaryHtml?: string;
  textHtml: string;
  extraTextHtml?: string;
  headerHtml?: string;
  imageUrl?: string;
  price: number;
  meta?: ProductOnlineMeta;
  publishedAt: string;
  visibility: number;
  status: number;
  OnlineTags?: OnlineTag[];
  RelatedOnlineProducts?: ProductOnlineDto[];
  ProductOnlineMaps?: ProductOnlineMapSequenceWithCategory[];
  Product?: ProductRecursiveCategory;
};
export type ProductsOnlineResponse = {
  code?: number;
  messages?: string[];
  result?: ProductOnline[];
};
export type ProductsByServiceGroupResponse = {
  code?: number;
  messages?: string[];
  result?: ServiceGroupDto[];
};
export type ProductOnlineResponse = {
  code?: number;
  messages?: string[];
  result?: ProductOnline;
};
export type RelatedProductsResponse = {
  code?: number;
  messages?: string[];
  result?: ProductOnlineDto[];
};
export type RelatedOnlinePutRequest = {
  onlineProductIds: string[];
};
export type CreateProductOnlineRequest = {
  productId: string;
  slug: string;
  name: string;
  nameHtml: string;
  summaryHtml?: string;
  textHtml: string;
  extraTextHtml?: string;
  imageUrl?: string;
  price: number;
  meta?: ProductOnlineMeta;
  publishedAt: string;
  visibility: number;
  onlineCategoryId?: string;
  tags?: string[];
  onlineProductIds?: string[];
  packageOnlineProductIds?: string[];
};
export type UpdateOnlineTagsResponse = {
  code?: number;
  messages?: string[];
  result?: OnlineTag[];
};
export type OnlineTagRequest = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  relationId: string;
  tag?: string;
  type: number;
  sequence: number;
  status: number;
};
export type OnlineTagsPutRequest = {
  onlineTags?: OnlineTagRequest[];
};
export type CategoryOnlineDto = {
  id: string;
  name: string;
  slug: string;
  sequence: number;
  imageUrl?: string;
  parentId?: string;
  meta?: object;
  headerHtml?: string;
  OnlineTags?: OnlineTag[];
};
export type OnlineItemsResponse = {
  CategoryList: CategoryOnlineDto[];
  ProductList: ProductOnlineDto[];
  Product: ProductOnlineDto;
};
export type RestrictionType = 0 | 1 | 2;
export type TreatmentOrderListDto = {
  orderNumber: string;
  orderFrom: string;
  name: string;
  email: string;
  restrictionType: RestrictionType;
  numberOfGuests?: number | string;
  numberOfServices?: number | string;
  reservedDate: string;
  reservationStatus: OrderStatus;
  totalAmount: number;
  orderDate: string;
  paymentStatus?: PaymentStatus;
  groupName?: string;
};
export type TreatmentOrderListResponse = {
  code?: number;
  messages?: string[];
  result?: TreatmentOrderListDto[];
  pageSize?: number;
  totalRecords?: number;
  totalPages?: number;
};
export type OrderCancellationRequestAppointment = {
  id: string;
  reason?: string;
  amount?: number;
  isWaived?: boolean;
};
export type OrderCancellationRequest = {
  appointments?: OrderCancellationRequestAppointment[];
  payment?: ConfirmOrderRequestPayment;
  isSendCancelConfirmation: boolean;
};
export type OrderRefundRequestOrderLine = {
  orderLineId: string;
  refundAmount: number;
};
export type OrderRefundRequest = {
  orderLines: OrderRefundRequestOrderLine[];
  isSendRefundConfirmation: boolean;
  note?: string | null;
};
export type TreatmentNotificationType = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type TreatmentOrderResendRequest = {
  receiptEmail: string;
  treatmentNotificationType: TreatmentNotificationType;
  phoneNumber?: string;
  recipientName?: string;
  isOverride?: boolean;
};
export type UpdateVaultResponse = {
  code?: number;
  messages?: string[];
  result?: TreatmentPaymentLines;
};
export type UpdateVaultRequest = {
  payment: ConfirmOrderRequestPayment;
  order?: TreatmentOrderDetailDto;
};
export type EditProfileRequest = {
  jsonPatch: Patch[];
  isOverrideBanWatchList?: boolean;
};
export type EmitAppointmentsRequest = {
  appointmentIds?: string[];
};
export type CustomerProfile = {
  email?: string;
  fullName: string;
};
export type OrderLineCustomer = {
  phoneNumber1?: string;
  Profile?: CustomerProfile;
};
export type OrderLineStatus = 0 | 1 | 2 | 3 | 9 | 21 | 22 | 23 | 24 | 31;
export type OrderLineListAppointmentDto = {
  id?: string;
  customerId?: string;
  serviceTime?: string;
  serviceStartAt?: string;
  serviceEndAt?: string;
  serviceDate?: string;
  status?: number;
  serviceName?: string;
  customerName?: string;
  therapistName?: string;
  preferenceGenderDescription?: string;
  preferenceGender?: PreferenceGender;
  bookingStatus: BookingStatus;
  bookingStatues: BookingStatues;
  cancelledTime?: string;
  cancelledDate?: string;
  cancelledBy?: string;
  serviceStationName?: string;
  notes?: NoteDto[] | null;
  orderLineStatus?: OrderLineStatus;
};
export type BuildingCheckInListDto = {
  sellingPrice: number;
  orderNumber: number;
  primaryContact: OrderLineCustomer;
  Appointments: OrderLineListAppointmentDto[];
  guestName: string;
  serviceDate: string;
  serviceNames: string[];
};
export type BuildingCheckInListResponse = {
  code?: number;
  messages?: string[];
  result: BuildingCheckInListDto[];
  pageSize?: number;
  totalRecords?: number;
  totalPages?: number;
};
export type Station = {
  id?: string;
  name?: string;
  reservationTreatmentInterval?: number;
  timeslots?: string[];
  roleIds?: string[];
};
export type StationResponse = {
  code?: number;
  messages?: string[];
  result?: Station[];
};
export type StationForTherapistSequence = {
  id?: string;
  name?: string;
};
export type StationForTherapistSequenceResponse = {
  code?: number;
  messages?: string[];
  result?: StationForTherapistSequence[];
};
export type PaymentLinesResponse = {
  code?: number;
  messages?: string[];
  result?: TreatmentPaymentLines[];
};
export type AppointmentWithBookingStatues = {
  id: string;
  appointmentId?: string;
  serviceName?: string;
  customerName?: string;
  serviceTime?: string;
  serviceDate?: string;
  bookingStatus?: BookingStatus;
  bookingStatues?: BookingStatues;
  icons?: Icons;
  orderId?: string | null;
  serviceGroup?: string;
  notes: NoteDto[];
};
export type AppointmentsByGuest = {
  id: string;
  customerName: string;
  Appointments: AppointmentWithBookingStatues[];
  meta?: AppointmentMeta;
  orderId?: string;
  serviceGroups: string[];
};
export type AppointmentListResponse = {
  code?: number;
  messages?: string[];
  result?: AppointmentsByGuest[];
};
export type AppointmentCancellationListResponseDtoAppointment = {
  id: string;
  appointmentId?: string;
  serviceName?: string;
  customerName?: string;
  serviceTime?: string;
  bookingStatus?: BookingStatus;
  bookingStatues?: BookingStatues;
  orderId?: string | null;
  sellingPrice: number;
  isServiceCancellationAvailable: boolean;
};
export type AppointmentCancellationListResponseDto = {
  serviceDate: string;
  Appointments: AppointmentCancellationListResponseDtoAppointment[];
  serviceTotalAmount: number;
  contactEmail: string;
  contact1Phone: string;
  contactRemindingType: RemindingType;
  paymentLine: TreatmentPaymentLines;
};
export type AppointmentCancellationListResponse = {
  code?: number;
  messages?: string[];
  result?: AppointmentCancellationListResponseDto;
};
export type AppointmentRefundListResponseResultAppointment = {
  id: string;
  serviceName?: string;
  refundableAmount: number;
  orderLineId: string;
  reason: string;
};
export type AppointmentRefundListResponseResultTender = {
  id: string;
  name: string;
};
export type AppointmentRefundListResponseResult = {
  email: string;
  phone: string;
  remindingType: RemindingType;
  appointments: AppointmentRefundListResponseResultAppointment[];
  tenders: AppointmentRefundListResponseResultTender[];
};
export type AppointmentRefundListResponse = {
  code?: number;
  messages?: string[];
  result?: AppointmentRefundListResponseResult;
};
export type PickTypeClass = {
  lockerNumber?: string;
  customerId?: string;
};
export type RateType = 1 | 2;
export type UpdateOrderLineRate = {
  /** tax Id */
  id?: string;

  /** tip & tax rate, *when gift certificate is applied, rate should be 0 unless gift certificate tip is customized */
  rate: number;
  type: RateType;
  tipAmount?: number;
};
export type UpdateOrderLineRequest = {
  id: string;
  orderId: string;
  rates: UpdateOrderLineRate[];
  giftCertificateId?: string;

  /** *master appointmentId needed for couple massage */
  appointmentId?: string;
};
export type UpdateAppointmentsStatusRequest = {
  /** edge customerId */
  id?: string;
  appointmentIds: string[];
  meta?: PickTypeClass | null;
  orderId?: string;
  OrderLine?: UpdateOrderLineRequest;
};
export type AppointmentGroup = {
  customerName: string;
  Appointments: AppointmentWithBookingStatues[];
  groupKey: string;
};
export type GroupAppointmentsResponse = {
  code?: number;
  messages?: string[];
  result?: AppointmentGroup[];
};
export type AppointmentStatuesResponse = {
  code?: number;
  messages?: string[];
  result?: AppointmentWithBookingStatues;
};
export type AddGroupAppointmentRequest = {
  id: string;

  /** groupKey used as groupName */
  groupKey: string;
  date?: string;

  /** true when adding new group, false when adding appointment to existing group */
  isNew: boolean;
};
export type CreateTimeslotBatchRequest = {
  fromDate?: string;
  toDate?: string | null;
};
export type CheckOutAppointment = {
  id?: string;
  customerId?: string;
  serviceTime?: string;
  serviceStartAt?: string;
  serviceEndAt?: string;
  serviceDate?: string;
  isForCouples?: boolean;
  appointmentType?: number;
  status?: number;
  appointmentId?: string;
  serviceId?: string;
  parentServiceId?: string;
  serviceName?: string;
  parentAppointmentServiceName?: string;
  customerName?: string;
  firstName?: string;
  lastName?: string;
  therapistName?: string;
  preferenceGenderDescription?: string;
  preferenceGender?: PreferenceGender;
  serviceCheckOutAt?: string | null;
  bookingStatus: BookingStatus;
  bookingStatues: BookingStatues;
  meta: AppointmentMeta;
  Notes: NoteDto[];
  cancelledTime?: string;
  cancelledDate?: string;
};
export type GiftCertificateDto = {
  id: string;
  name: string;
  servicePrice: number;
  sellingPrice: number;
  discountAmount: number;
  tipAmount: number;
  isCommission: boolean;
  validUntil: string;
  capacity: number;
  scheduledId: string;
  legacyCode: string;
  description: string;
};
export type CheckOutOrderLines = {
  id: string;
  orderId: string;
  listPrice: number;
  sellingPrice: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  promotionAmount: number;
  totalAmount: number;
  refundAmount: number;
  status: number;
  meta?: OrderLineMeta;
  isSelected: boolean;
  paidAmount: number;
  Appointment: CheckOutAppointment;
  DiscountList?: GiftCertificateDto[] | null;
};
export type CheckOutAppointmentsResponseDto = {
  OrderLines: CheckOutOrderLines[];
};
export type CheckOutAppointmentsResponse = {
  code?: number;
  messages?: string[];
  result?: CheckOutAppointmentsResponseDto;
};
export type CancelledOrderLinesDto = {
  id: string;
  orderId: string;
  orderLineId: string;
  endOfDateId: string;
  feeAmount: number;
  reason: string;
  meta: object;
  isWaived: boolean;
  status: number;
};
export type RefundedOrderLinesDto = {
  id: string;
  orderId: string;
  orderLineId: string;
  endOfDateId: string;
  refundedAmount: number;
  reason: string;
  meta: object;
  status: number;
};
export type OrderLineListDto = {
  sellingPrice: number;
  status: number;
  orderNumber?: number;
  Customer?: OrderLineCustomer;
  Appointment: OrderLineListAppointmentDto;
  CancelledOrderLine?: CancelledOrderLinesDto | null;
  RefundedOrderLines?: RefundedOrderLinesDto[] | null;
  PaymentLines?: TreatmentPaymentLines[] | null;
  orderLinePaymentStatus?: PaymentStatusDisplay | null;
};
export type AppointmentsListResponse = {
  code?: number;
  messages?: string[];
  result: OrderLineListDto[];
  pageSize?: number;
  totalRecords?: number;
  totalPages?: number;
};
export type CancelledListType = 0 | 1;
export type VoidCheckedOutOrderRequest = {
  /** order Id */
  id: string;

  /** orderLine Ids */
  orderLineIds?: string[];
};
export type TreatmentOrderBatchRequest = {
  fromDate?: string;
  toDate?: string | null;
};
export const {
  usePostAppointmentServiceMutation,
  usePostAppointmentBlockMutation,
  usePostReservationMutation,
  usePatchReservationMutation,
  usePatchAppointmentServiceMutation,
  usePatchAppointmentBlockMutation,
  useGetCartForReservationTreatmentQuery,
  useRenewTimeslotForReservationTreatmentMutation,
  useReleaseTimeslotForReservationTreatmentMutation,
  usePostTreatmentCartNumberOfGuestsMutation,
  useAddGuestMutation,
  useUpdateTreatmentCartMutation,
  useDeleteCartForReservationTreatmentMutation,
  usePostTreatmentCartAddMutation,
  useCreateIncompleteOrderForReservationTreatmentMutation,
  useUpdateIncompleteOrderForReservationTreatmentMutation,
  useConfirmOrderForReservationTreatmentMutation,
  usePlaceOrderForReservationTreatmentMutation,
  usePatchPlaceOrderForReservationTreatmentMutation,
  useGetStaffsQuery,
  useGetSchedulesByResourceQuery,
  useGetAllSchedulesByResourceQuery,
  useUpdateAppointmentMutation,
  useGetAppointmentNotesQuery,
  useCreateAppointmentNoteMutation,
  useUpdateAppointmentNoteMutation,
  useDeleteAppointmentNoteMutation,
  useGetAvailableDateslotsQuery,
  useGetPrepareEditAppointmentQuery,
  useGetPrepareNewAppointmentQuery,
  useGetAvailableTherapistsQuery,
  useGetServiceTimeslotsQuery,
  useGetTreatmentAvailableTimeslotsQuery,
  useGetAvailableTimeslotsByProductQuery,
  useGetOnlineCategoriesQuery,
  useGetOnlineCategoryQuery,
  useUpdateOnlineCategoryMutation,
  useDeleteOnlineCategoryMutation,
  useCreateOnlineCategoryMutation,
  useGetOnlineProductsQuery,
  useGetProductsByServiceGroupQuery,
  useGetOnlineProductQuery,
  useUpdateOnlineProductDetailMutation,
  useDeleteOnlineProductMutation,
  useGetRelatedOnlineProductsQuery,
  useUpdateRelatedOnlineProductsMutation,
  useGetProductPackageListQuery,
  useCreateOnlineProductMutation,
  useUpdateOnlineTagsMutation,
  useGetOnlineItemsBySlugQuery,
  useTreatmentOrderListQuery,
  useTreatmentOrderQuery,
  useConfirmedTreatmentOrderQuery,
  useCancelTreatmentOrderMutation,
  usePatchPartialCancelTreatmentOrderMutation,
  usePostOrderCancellationMutation,
  usePostOrderRefundMutation,
  useTreatmentOrderResendMutation,
  useUpdateVaultMutation,
  useEditProfileMutation,
  useEmitAppointmentsMutation,
  useGetAppointmentsQuery,
  useGetStationsForReservationTreatmentQuery,
  useGetStationsForTherapistSequenceQuery,
  useGetPaymentLinesQuery,
  useGetRelatedAppointmentsForStatusChangeQuery,
  useGetRelatedAppointmentsForCancellationQuery,
  useGetAppointmentsForRefundQuery,
  useUpdateAppointmentsForStatusMutation,
  useGetGroupAppointmentsQuery,
  useAddGroupAppointmentMutation,
  useRemoveGroupAppointmentMutation,
  useTimeslotBatchMutation,
  useCheckOutAppointmentsQuery,
  useCancelledAppointmentsQuery,
  useVoidCheckedOutOrderMutation,
  useTreatmentOrderConfirmationBatchMutation,
  useTreatmentOrderSurveyBatchMutation,
  useConfirmAppointmentsMutation,
} = injectedRtkApi;
