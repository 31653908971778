import { InputBaseComponentProps } from '@mui/material';
import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React, { ComponentProps, forwardRef } from 'react';

type CleaveInputProps = InputBaseComponentProps & {
  options: CleaveOptions;
  onChange: (x?: string) => void;
};

const onInit: ComponentProps<typeof Cleave>['onInit'] = owner => {
  (owner as any).lastInputValue = '';
};

export const CleaveInput = forwardRef<HTMLInputElement, CleaveInputProps>((props, ref) => {
  return (
    <Cleave
      {...props}
      onInit={onInit}
      options={props.options}
      htmlRef={(inputNode: HTMLInputElement) => {
        if (!ref) {
          return;
        }
        if (typeof ref === 'function') {
          ref(inputNode);
        } else {
          ref.current = inputNode;
        }
      }}
      onChange={e => props.onChange(e.target.rawValue)}
    />
  );
});

export default CleaveInput;
