import React from 'react';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import PropTypes from 'prop-types';
import createEmotionCache from '../utility/createEmotionCache';
import { ConfirmationServiceProvider } from '@edge/frontend.component';
import '../styles/globals.css';
import { wrapper } from '../store';
import { Provider } from 'react-redux';
import { ReactElement, ReactNode } from 'react';
import { NextPage } from 'next/types';
import { GoogleAnalytics, MetaPixel } from '@edge/frontend.component';
import { Provider as SessionProvider } from 'views/account/components/provider';
import { ConfirmationDialog } from 'components/confirmationDialog';

const clientSideEmotionCache = createEmotionCache();
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const MyApp = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { store } = wrapper.useWrappedStore(pageProps);
  const getLayout = Component.getLayout ?? ((page: any) => page);
  return (
    <Provider store={store}>
      <SessionProvider>
        <CacheProvider value={emotionCache}>
          <CssBaseline />
          <GoogleAnalytics trackPageViews={true} gaMeasurementId="UA-96043347-1" debugMode={false} />
          <MetaPixel PIXEL_ID="1928081710816985" />
          <ConfirmationServiceProvider confirmationDialog={<ConfirmationDialog />}>
            <>{getLayout(<Component {...pageProps} />)}</>
          </ConfirmationServiceProvider>
        </CacheProvider>
      </SessionProvider>
    </Provider>
  );
};

export default MyApp;

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
