import { FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormControl from './formControl';
import { FormControlProps, FormInputProps, IHasChildren, InnerFormInput } from './interfaces';
import { forwardRef } from 'react';

interface IHasErrorMessage {
  hasErrorMessage?: boolean;
}
type InnerRadioInputProps = InnerFormInput & IHasChildren & IHasErrorMessage;
type RadioInputProps = FormInputProps & IHasErrorMessage;
export const InnerRadioInput = forwardRef<React.MutableRefObject<HTMLInputElement>, InnerRadioInputProps>((props, ref) => {
  const hasErrorMessage = props.hasErrorMessage ?? true;
  // TODO: setFocus가 될 경우 focus가 오지 않음, children의 첫번째 FormLabel의 ref에 넣으면 작동한다. 변경 필요.
  return (
    <>
      <FormLabel error={Boolean(props.error)} id={`demo-radio-buttons-group-${props.label}`}>
        {props.label}
      </FormLabel>
      <RadioGroup ref={ref} row value={props.value || ''} onChange={props.onChange} defaultChecked={Boolean(props.value)}>
        {props.children}
      </RadioGroup>
      {props.hasErrorMessage && props.error?.message && (
        <FormHelperText error id={`password-error-${props.label}`}>
          {props.error.message}
        </FormHelperText>
      )}
    </>
  );
});

export const RadioInput = (props: RadioInputProps) => {
  return (
    <FormControl {...props} renderedComponent={<InnerRadioInput hasErrorMessage={props.hasErrorMessage}></InnerRadioInput>} name={props.name} label={props.label} rules={props.rules}>
      {props.children}
    </FormControl>
  );
};
export default RadioInput;
