import { emptySplitApi as api } from './emptyApi';

export const addTagTypes = ['getProductsFromWp', 'getProductFromWp', 'getTheme'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getProductsFromWp: build.query<GetProductsFromWpApiResponse, GetProductsFromWpApiArg>({
        query: () => ({ url: '/shop/products' }),
        providesTags: ['getProductsFromWp'],
      }),
      getProductFromWp: build.query<GetProductFromWpApiResponse, GetProductFromWpApiArg>({
        query: queryArg => ({ url: `/shop/product/${queryArg.slug}` }),
        providesTags: ['getProductFromWp'],
      }),
      getTheme: build.query<GetThemeApiResponse, GetThemeApiArg>({
        query: queryArg => ({
          url: '/shop/theme',
          params: { path: queryArg.path },
        }),
        providesTags: ['getTheme'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeShopApi };
export type GetProductsFromWpApiResponse = /** status 200  */ object;
export type GetProductsFromWpApiArg = void;
export type GetProductFromWpApiResponse = /** status 200  */ object;
export type GetProductFromWpApiArg = {
  slug: string;
};
export type GetThemeApiResponse = /** status 200  */ object;
export type GetThemeApiArg = {
  path: any;
};
export const { useGetProductsFromWpQuery, useGetProductFromWpQuery, useGetThemeQuery } = injectedRtkApi;
