import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import 'cleave.js/dist/addons/cleave-phone.us';
import { FormControl as MuiFormControl } from '@mui/material';
import { FormControlProps } from './interfaces';

const FormControl = (props: FormControlProps) => {
  const form = useFormContext();
  const { renderedComponent, ...rest } = props;
  return (
    <MuiFormControl fullWidth>
      <Controller
        name={props.name}
        control={form.control}
        rules={props.rules}
        render={({ field: { onChange, value, ref }, formState: { isSubmitting }, fieldState: { error } }) => {
          return React.cloneElement(renderedComponent, { ...rest, value: value, onChange: onChange, error: error, isSubmitting: isSubmitting, ref: ref });
        }}
      />
    </MuiFormControl>
  );
};

export default FormControl;
